import React from "react";
import logoMatriz from "../../imagenes/matriz_aniversario.png";
import apiSucursal from "../../services/sucursalApi";
import {
  Layer,
  Map,
  Marker,
  NavigationControl,
  Popup,
  Source,
} from "react-map-gl/maplibre";
import apiKeys from "../../utils/api-Keys.util";
import Pin from "../components/maps/pin";

// Estilos
import "../../styles/Agencias.css";
import "maplibre-gl/dist/maplibre-gl.css";

class Agencias extends React.Component {
  state = {
    sucursales: [],
    loading: false,
    error: false,
    selectedSucursal: null,
    viewPort: [
      {
        longitud: -79.521, // Longitud centrica de todas las agencias
        latitud: -0.956, // Latitud centrica de todas las agencias
      },
    ],
    userLocation: null, // Propiedad para la ubicación del usuario
    ruta: null, // Propiedad para almacenar la ruta
  };

  componentDidMount() {
    this.getAgencias();
    this.getUserLocation();
  }

  // Obtener las coordenadas de la ubicación actual del usuario
  getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({
            userLocation: [position.coords.longitude, position.coords.latitude],
          });
        },
        (error) => {
          console.error("Error al obtener la ubicación: ", error);
        }
      );
    }
  };

  getAgencias = async () => {
    this.setState({ loading: true });
    try {
      const data = await apiSucursal.sucursal.getall();
      const sucursalesWithCoordinates = this.addCoordinatesAndSchedule(
        data.response
      );
      this.setState({
        loading: false,
        sucursales: sucursalesWithCoordinates,
        error: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  addCoordinatesAndSchedule(agencias) {
    const agenciasCoordinatesAndSchedule = {
      "MATRÍZ LA MANÁ": {
        latitud: -0.941473,
        longitud: -79.22909,
        lunAVie: "08:00 am – 17:00 pm",
        sabADom: "09:00 am – 13:00 pm",
      },
      "EL EMPALME": {
        latitud: -1.045371,
        longitud: -79.635421,
        lunAVie: "08:00 am – 16:00 pm",
        sabADom: "No hay atención",
      },
      "BUENA FE": {
        latitud: -0.894011,
        longitud: -79.489638,
        lunAVie: "08:00 am – 16:00 pm",
        sabADom: "09:00 am – 13:00 pm",
      },
      BALZAR: {
        latitud: -1.367189,
        longitud: -79.906714,
        lunAVie: "08:00 am – 16:00 pm",
        sabADom: "No hay atención",
      },
      PEDERNALES: {
        latitud: 0.070207,
        longitud: -80.055684,
        lunAVie: "08:00 am – 16:00 pm",
        sabADom: "No hay atención",
      },
      "EL CARMEN": {
        latitud: -0.272484,
        longitud: -79.45872,
        lunAVie: "08:00 am – 16:00 pm",
        sabADom: "No hay atención",
      },
      "EL PARAÍSO LA 14": {
        latitud: -0.588901,
        longitud: -79.512227,
        lunAVie: "08:00 am – 16:00 pm",
        sabADom: "09:00 am – 13:00 pm",
      },
    };

    return agencias.map((sucursal) => {
      const coord = agenciasCoordinatesAndSchedule[sucursal.nombre];
      return coord
        ? { ...sucursal, latitud: coord.latitud, longitud: coord.longitud, lunAVie: coord.lunAVie, sabADom: coord.sabADom }
        : sucursal;
    });
  }

  // Límites de la rutas
  getRouteBounds = (rutaCoords) => {
    const bounds = rutaCoords.reduce(
      (acc, coord) => {
        acc[0] = Math.min(acc[0], coord[0]);
        acc[1] = Math.min(acc[1], coord[1]);
        acc[2] = Math.max(acc[2], coord[0]);
        acc[3] = Math.max(acc[3], coord[1]);
        return acc;
      },
      [Infinity, Infinity, -Infinity, -Infinity]
    );

    return bounds; // [minLat, minLon, maxLat, maxLon]
  };

  // Referencia al mapa
  mapRef = React.createRef();

  // Función para trazar la ruta entre la ubicación del usuario y la sucursal
  traceRoute = async (lat, lon) => {
    const { userLocation } = this.state;

    if (userLocation) {
      try {
        const response = await fetch(
          `https://api.openrouteservice.org/v2/directions/driving-car?api_key=${apiKeys.OPENROUTESERVICE}&start=${userLocation[0]},${userLocation[1]}&end=${lon},${lat}`
        );
        const data = await response.json();
        console.log(data)
        const rutaCoords = data.features[0].geometry.coordinates;
        // Calcular los límites de la ruta
        const bounds = this.getRouteBounds(rutaCoords);

        // Actualizar el estado de la ruta y el mapa
        this.setState({ ruta: rutaCoords, selectedSucursal: null });

        // Hacer zoom y centrar el mapa en la ruta
        this.mapRef.current.fitBounds(bounds, { padding: 50 });
      } catch (error) {
        console.error("Error al obtener la ruta:", error);
      }
    }
  };

  renderLoadingSpinner = () => {
    if (this.state.loading) {
      return (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          Cargando...
        </div>
      );
    }
  };

  renderErrorMessage = () => {
    if (this.state.error) {
      return (
        <div className="alert alert-danger" role="alert">
          Hubo un error al cargar las sucursales. Por favor, intente más tarde.
        </div>
      );
    }
  };

  render() {
    const { sucursales, selectedSucursal, viewPort, ruta } = this.state;

    return (
      <React.Fragment>
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center text-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1 className="mb-4">Nuestras agencias</h1>
                <h2 className="mb-4">
                  Estamos siempre cerca de ti. Visítanos en nuestra matriz
                  ubicada en La Maná o en cualquiera de nuestras 6 agencias y
                  accede a soluciones financieras seguras y rápidas, diseñadas
                  para ti. ¡Te esperamos!
                </h2>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 text-center"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img
                  src={logoMatriz}
                  className="logovertical"
                  alt="logo-matriz"
                />
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section id="services" className="services section-bg">
            <br /> <br />
            <div className="container" data-aos="fade-up">
              {this.renderLoadingSpinner()}
              {this.renderErrorMessage()}
              <div className="row">
                {/* Columna para el mapa */}
                <div
                  className="col-lg-8 col-md-8 order-md-1 order-2 p-2"
                  style={{ height: "600px" }}
                >
                  <Map
                    ref={this.mapRef}
                    initialViewState={{
                      longitude: viewPort[0].longitud,
                      latitude: viewPort[0].latitud,
                      zoom: 7,
                    }}
                    mapStyle={`https://api.maptiler.com/maps/streets/style.json?key=${apiKeys.MAPTILER}`}
                  >
                    <NavigationControl position="top-left" />
                    {sucursales.map((sucursal, i) => {
                      const { latitud, longitud } = sucursal;
                      if (
                        !latitud ||
                        !longitud ||
                        isNaN(latitud) ||
                        isNaN(longitud)
                      ) {
                        return null;
                      }

                      return (
                        <Marker
                          key={`marker-${i}`}
                          longitude={longitud}
                          latitude={latitud}
                          anchor="bottom"
                          onClick={(e) => {
                            e.originalEvent.stopPropagation();
                            this.setState({ selectedSucursal: sucursal });
                          }}
                        >
                          <Pin />
                        </Marker>
                      );
                    })}

                    {selectedSucursal && (
                      <Popup
                        anchor="top"
                        longitude={selectedSucursal.longitud}
                        latitude={selectedSucursal.latitud}
                        onClose={() =>
                          this.setState({ selectedSucursal: null })
                        }
                      >
                        <div>
                          <span
                            className="fw-bold"
                            style={{ fontSize: "small" }}
                          >
                            {selectedSucursal.nombre !== "MATRÍZ LA MANÁ"
                              ? "AGENCIA " + selectedSucursal.nombre
                              : selectedSucursal.nombre}
                          </span>
                          <p>
                            <b>Dirección: </b> {selectedSucursal.direccion}
                          </p>
                          <button
                            className="btn btn-success btn-sm w-100"
                            onClick={() =>
                              this.traceRoute(
                                selectedSucursal.latitud,
                                selectedSucursal.longitud
                              )
                            }
                          >
                            <img
                              src="./images/route.svg"
                              alt="routes.svg"
                              style={{ marginRight: 8 }}
                            />
                            Cómo llegar
                          </button>
                        </div>
                      </Popup>
                    )}

                    {ruta && (
                      <Source
                        id="ruta"
                        type="geojson"
                        data={{
                          type: "Feature",
                          properties: {},
                          geometry: {
                            type: "LineString",
                            coordinates: ruta,
                          },
                        }}
                      >
                        <Layer
                          id="ruta-layer"
                          type="line"
                          paint={{ "line-color": "#ff0000", "line-width": 4 }}
                        />
                      </Source>
                    )}
                  </Map>
                </div>

                {/* Columna para las agencias */}
                <div
                  className="col-lg-4 col-md-4 p-2 mb-md-0 mb-4 order-md-2 order-1"
                  style={{ height: "600px", overflowY: "auto" }}
                >
                  {sucursales.map((sucursal) => (
                    <div key={sucursal.idsucursal} className="box-agencias">
                      <h4>
                        {sucursal.nombre !== "MATRÍZ LA MANÁ"
                          ? "AGENCIA " + sucursal.nombre
                          : sucursal.nombre}
                      </h4>
                      <p>
                        <b>Dirección: </b>
                        {sucursal.direccion}
                      </p>
                      <p>
                        <b>Teléfono: </b>
                        {sucursal.telefono}
                      </p>
                      <p>
                        <b>Email: </b>info@futurolamanense.fin.ec
                      </p>
                      <div>
                        <b>Horarios de atención:</b>
                        <ul>
                          <li>Lunes a Viernes ({sucursal.lunAVie})</li>
                          <li>Sábado y Domingo ({sucursal.sabADom})</li>
                        </ul>
                      </div>
                      <button
                        className="btn btn-success btn-sm w-100"
                        onClick={() =>
                          this.traceRoute(sucursal.latitud, sucursal.longitud)
                        }
                      >
                        <img
                          src="./images/route.svg"
                          alt="routes.svg"
                          style={{ marginRight: 8 }}
                        />
                        <span>Cómo llegar</span>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <br /> <br />
            </div>
            <br />
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default Agencias;
