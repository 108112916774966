import React, { Component } from "react";
import { JobOfferApi } from "../../services/JobOfferApi";
import JobOfferCard from "../components/jobs/JobOfferCard";
import EmptyResource from "../../imagenes/noticias/svg/Empty-resource.svg";
import EmptyImageComponent from "../components/noticias/EmptyImageComponent";
import "../../styles/Job.css";

class JobOfferList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobOfferList: [],
      isLoading: false,
    };
  }

  async componentDidMount() {
    const response = await JobOfferApi.getAllJobOffers();
    console.log(response)
    this.setState({
      jobOfferList: response,
      isLoading: false,
    });
  }

  render() {
    const { jobOfferList, isLoading } = this.state;

    return (
      <>
        <section
          id="hero"
          className="d-flex align-items-center"
          style={{ height: "25vh" }}
        >
          <div className="container mt-4">
            <div className="row">
              <div
                className="col-lg-4 d-flex flex-column justify-content-center text-center pt-lg-0"
                data-aos="fade-up"
              >
                <h1>Trabaja con nosotros</h1>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row p-5" data-aos="fade-up">
            {!isLoading ? (
              jobOfferList.length > 0 ? (
                jobOfferList.map((jobOffer) => {
                  return (
                    <div
                      className="col-sm-6 col-md-4 mb-5 d-flex justify-content-center"
                      key={jobOffer.id}
                    >
                      <JobOfferCard jobOffer={jobOffer} />
                    </div>
                  );
                })
              ) : (
                <div className="d-flex justify-content-center">
                  <EmptyImageComponent
                    srcImage={EmptyResource}
                    description={"No existen ofertas de trabajo"}
                  />
                </div>
              )
            ) : (
              <span className="loader mx-auto"></span>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default JobOfferList;
