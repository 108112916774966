import React, { Component } from "react";

class JobOfferCard extends Component {
  render() {
    const { jobOffer } = this.props;

    return (
      <div className="card" style={{ width: "300px" }}>
        {jobOffer.isActive === 0 ? (
          <div className="state-label" style={{ backgroundColor: "#e60055" }}>
            Contratado
          </div>
        ) : (
          <div className="state-label" style={{ backgroundColor: "#77B735" }}>
            Disponible
          </div>
        )}
        <img
          src={
            jobOffer.imageUrl === ""
              ? "./images/default.jpg"
              : jobOffer.imageUrl
          }
          className="card-img-top"
          alt="Imagen de la oferta de trabajo"
        />
        <div className="card-body">
          <h5
            className="card-title text-pretty-2"
            style={{ minHeight: "50px" }}
          >
            {jobOffer.title} - Futuro Lamanense
          </h5>
          <span className="card-subtitle mb-2 text-muted text-md">
            {jobOffer.branch}
          </span>
          <p className="card-text text-pretty-3">{jobOffer.description}</p>
          <a
            href={`/job-offers/${jobOffer.id}`}
            className="btn fl-btn-primary px-3"
          >
            Ver más
          </a>
        </div>
      </div>
    );
  }
}

export default JobOfferCard;
