import React from "react";
import AhorroCampoImg from "../../imagenes/ahorro-campo.jpg";
import apiVisitas from "../../services/visitasApi";

class AhorroCampo extends React.Component {
  agregarVisitaAhorroCampo = async () => {
    try {
      await apiVisitas.visitas.create({ tipo: "Ahorro Campo" });
    } catch (error) {
      console.log("error al crear visíta " + error);
    }
  };

  componentDidMount() {
    this.agregarVisitaAhorroCampo();
  }

  render() {
    return (
      <React.Fragment>
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1>Ahorro Campo</h1>
                <h2 className="texto-justificado">
                  Nuestro producto financiero facilita el ahorro mediante
                  recaudaciones en campo: ya sea en tu domicilio, lugar de
                  trabajo o cualquier ubicación accesible. Con un plazo mínimo
                  de tres meses, podrás acumular tus fondos y retirarlos junto
                  con un 5% de interés anual.
                </h2>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img
                  src={AhorroCampoImg}
                  className="w-75 img-fluid rounded"
                  alt="img-ahorroCampo"
                />
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section id="services" className="services section-bg">
            <br /> <br />
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div
                  className="col-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Requisitos
                    </h5>
                  </div>
                  <ul style={{ fontSize: "large" }}>
                    <li>Copia a color de la cédula.</li>
                    <li>
                      Copia de la última planilla de cualquier servicio básico.
                    </li>
                    <li>Correo electrónico</li>
                  </ul>
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Monto de Apertura
                    </h5>
                  </div>
                  <span style={{ fontSize: "large" }}>
                    El monto requerido para la apertura de la cuenta es de USD
                    $5.00
                  </span>
                  <br />
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Beneficios
                    </h5>
                  </div>
                  <ul style={{ fontSize: "large" }}>
                    <li>
                      Gestiona tu cuenta desde cualquier lugar con nuestra{" "}
                      <a href="https://bit.ly/billeteradigital" target="_blank">
                        aplicación móvil
                      </a>{" "}
                      o{" "}
                      <a
                        href="https://bdfl.futurolamanense.fin.ec/login"
                        target="_blank"
                      >
                        banca web
                      </a>
                      , fácil, rápido y seguro.
                    </li>
                    <li>
                      Disfruta de una tasa de interés anual del 5% sobre el
                      monto ahorrado.
                    </li>
                  </ul>
                </div>
              </div>
              <br />
            </div>
            <br />
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default AhorroCampo;
