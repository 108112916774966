import React from "react";
import FondoMortuorioiMG from "../../imagenes/fondomortuorio.jpg";
import apiVisitas from "../../services/visitasApi";

class FondoMortuorio extends React.Component {

    agregarVisitaFondoMortuorio = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Fondo Mortuorio" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        this.agregarVisitaFondoMortuorio();
    }

    render() {
        return (
          <React.Fragment>
            <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h1>Fondo Mortuorio</h1>
                    <h2>
                      Todos los Socios que mantiene la cuenta con el movimiento
                      tienen el beneficio de un fondo mortuorio.
                    </h2>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src={FondoMortuorioiMG}
                      className="img-fluid  rounded w-75"
                      alt="img-fondoMortuorio"
                    />
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="services" className="services section-bg">
                <br /> <br />
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div
                      className="col-12 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Requisitos
                        </h5>
                      </div>
                      <ul style={{ fontSize: "large" }}>
                        <li>Partida de defunción original.</li>
                        <li>
                          Copia de la partida de nacimiento o copia de cédula
                          del beneficiario y del socio difunto.
                        </li>
                        <li>Copia de certificado del INEC.</li>
                        <li>Solicitud para la obtención del seguro.</li>
                      </ul>
                      <br />
                    </div>
                    <div
                      className="col-12 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">Beneficios</h5>
                      </div>
                      <ul style={{ fontSize: "large" }}>
                        <li>Obtén un fondo mortuorio de $1.000</li>
                      </ul>
                      <br />
                    </div>
                  </div>
                  <br />
                </div>
                <br />
              </section>
            </main>
          </React.Fragment>
        );
    }
}

export default FondoMortuorio;