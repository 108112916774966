import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import AhorroVista from "./../../imagenes/ahorro-a-la-vista.jpg";
import AhorroInversion from "./../../imagenes/ahorroinversion.jpeg";
import AhorroJunior from "./../../imagenes/ahorrojunior.jpg";
import AhorroProgramado from "./../../imagenes/ahorroprogrmado.jpeg";
import AhorroCampo from "./../../imagenes/ahorro-campo.jpg";
import AhorroInclusion from "./../../imagenes/ahorroinclusion.jpg";
import AhorroInclusionJunior from "./../../imagenes/inclusion-junior.jpg";
import AhorroDual from "./../../imagenes/ahorro-dual.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../styles/Slider-swiper.css";

// Datos de productos de ahorro
const productosAhorro = [
  {
    title: "Ahorro a la Vista",
    image: AhorroVista,
    description:
      "¡Tu dinero siempre a la mano! Disfrutá de total flexibilidad y seguridad para disponer de tu ahorro cuando lo necesites.",
    link: "/cuenta-ahorros",
    delay: 100,
  },
  {
    title: "Ahorro Junior",
    image: AhorroJunior,
    description:
      "Fomentá el hábito del ahorro desde joven. ¡Una cuenta diseñada para menores de 16 años con total facilidad y control!",
    link: "/ahorro-junior",
    delay: 200,
  },
  {
    title: "Ahorro Programado",
    image: AhorroProgramado,
    description:
      "Ahorro garantizado mes a mes. ¡Bloqueá tus ahorros por un tiempo y obtén resultados al final de tu plazo!",
    link: "/ahorro-programado",
    delay: 300,
  },
  {
    title: "Ahorro Campo",
    image: AhorroCampo,
    description:
      "¡Ahorra desde tu domicilio o lugar de trabajo! Generá un 5% de interés anual en solo tres meses.",
    link: "/ahorro-campo",
    delay: 300,
  },
  {
    title: "Ahorro Inclusión",
    image: AhorroInclusion,
    description:
      "Un producto inclusivo para todos. Fomentá el ahorro y empoderá a jóvenes, adultos mayores y comunidades desatendidas.",
    link: "/ahorro-inclusion",
    delay: 400,
  },
  {
    title: "Ahorro Inclusión Junior",
    image: AhorroInclusionJunior,
    description:
      "Fomenta el ahorro y la planificación financiera para niños, jóvenes, personas discapacitadas y mujeres embarazadas.",
    link: "/ahorro-inclusion-junior",
    delay: 500,
  },
  {
    title: "Ahorro Dual",
    image: AhorroDual,
    description:
      "¡Ahorra de forma segura y flexible! Un producto gestionado por dos personas, que te permite hacer depósitos y retiros cuando lo necesites.",
    link: "/ahorro-dual",
    delay: 600,
  },
  {
    title: "Depósito a plazo fijo",
    image: AhorroInversion,
    description:
      "Eleva tus ahorros con plazos flexibles y una tasa de interés competitiva.",
    link: "/ahorro-inversion",
    delay: 700,
  },
];

class Ahorroproductos extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="services" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Ahorra y Piensa en tu Futuro</h2>
              <p>
                Empieza a ahorrar de forma segura con nosotros. Conoce nuestros
                productos de ahorro y elige el que mejor se adapte a tus
                necesidades.
              </p>
            </div>

            <Swiper
              style={{ width: "100%", height: 450 }}
              slidesPerView={1}
              slidesPerGroupSkip={1}
              centeredSlides={false}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 3,
                },
              }}
              grabCursor={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Navigation, Pagination, Autoplay]}
            >
              {productosAhorro.map((producto, index) => (
                <SwiperSlide
                  key={index}
                  style={{ top: "5%" }}
                  className="d-flex justify-content-center"
                >
                  <div
                    className="slide-item"
                    data-aos="zoom-in"
                    data-aos-delay={producto.delay}
                  >
                    <div className="box-products">
                      <Link to={producto.link}>
                        <div className="icon d-flex justify-content-center">
                          <img src={producto.image} alt={producto.title} />
                        </div>
                        <h4 className="text-center fw-bold mt-3">{producto.title}</h4>
                        <p className="texto-justificado text-secondary">
                          {producto.description}
                        </p>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Ahorroproductos;
