import React from "react";
import logoVertical from "../../imagenes/logoblancas.png";
import { PDFViewer } from 'react-view-pdf';
import { VictoryChart, VictoryLine, VictoryTheme } from "victory";

class Indicadoresgenero extends React.Component {


    render() {
        const series = [
          {
            name: "Canada",
            data: [
              3.9670002, 5.2650003, 6.201, 7.8010006, 9.694, 11.214001,
              11.973001, 12.250001, 12.816001, 13.413001, 13.626961, 14.30356,
              15.295461,
            ],
          },
        ];

        return (
          <React.Fragment>
            <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-12 d-flex flex-column  pt-4 pt-lg-0 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="text-center">
                      <h1>Indicadores de Género</h1>
                      <span className="d-block fs-5 fw-medium text-white opacity-75">
                        Diciembre 31 del 2024
                      </span>
                    </div>

                    <div className="text-center pt-0">
                      <img
                        src={logoVertical}
                        className="logovertical-gerencia"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <br /> <br />
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="alert alert-primary" role="alert">
                      <h5 className="texto-justificado mb-0 fw-bold">
                        Indicador Género Depósitos
                      </h5>
                    </div>
                    <PDFViewer url="https://futurolamanense.fin.ec/docs/genero/IndicadorGeneroDepositos_Report31122022.pdf" />
                  </div>
                  <div className="col-lg-6">
                    <div className="alert alert-primary" role="alert">
                      <h5 className="texto-justificado mb-0 fw-bold">
                        Indicador Género Acceso Créditos
                      </h5>
                    </div>
                    <PDFViewer url="https://futurolamanense.fin.ec/docs/genero/IndicadorGeneroAccesoCreditos_Report31122022.pdf" />
                  </div>
                </div>
                <br /> <br />
                <div className="row">
                  <div className="col-lg-6">
                    <div className="alert alert-primary" role="alert">
                      <h5 className="texto-justificado mb-0 fw-bold">
                        Indicador Género Monto Promedio
                      </h5>
                    </div>
                    <PDFViewer url="https://futurolamanense.fin.ec/docs/genero/IndicadorGeneroMontoPromedio_Report31122022.pdf" />
                  </div>
                  <div className="col-lg-6">
                    <div className="alert alert-primary" role="alert">
                      <h5 className="texto-justificado mb-0 fw-bold">
                        Indicador Género Mora
                      </h5>
                    </div>
                    <PDFViewer url="https://futurolamanense.fin.ec/docs/genero/IndicadorGeneroMora_Report31122022.pdf" />
                  </div>
                </div>
                <br /> <br />
                <div className="row">
                  <div className="col-lg-6">
                    <div className="alert alert-primary" role="alert">
                      <h5 className="texto-justificado mb-0 fw-bold">
                        Indicador Género Representación
                      </h5>
                    </div>
                    <PDFViewer url="https://futurolamanense.fin.ec/docs/genero/IndicadorGeneroRepresentacion_Report31122022.pdf" />
                  </div>
                </div>
              </div>
              <br /> <br />
            </main>
          </React.Fragment>
        );
    }
}//

export default Indicadoresgenero;