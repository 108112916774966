import React from "react";
import creditonavidenoMobile from './../../../imagenes/baners-paginas/futunavideno.png';
import SimuladorCredito from "../simulador-credito";

class FutuNavideno extends React.Component {
    render(){
        return (
          <React.Fragment>
            <section id="hero" className="d-flex align-items-center mb-0">
              <div className="container mb-0 pb-0">
                <div className="row mb-0 pb-0">
                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 mb-0 pb-0 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h1>Crédito Futunavideño</h1>
                    <h2 className="mb-3">
                      Es dirigido a socios que tengan negocio establecido para
                      el comercio, servicio, produccion (capital de trabajo o
                      activos fijos), por temporada navideña. Aplica solo en el
                      mes de Dic 2025.
                    </h2>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2  text-center mb-0 pb-0"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src={creditonavidenoMobile}
                      className="rounded img-fluid w-50"
                      alt="credito-futunavideño"
                    />
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="services" className="services section-bg">
                <br /> <br />
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0">Requisitos</h5>
                      </div>
                      <ul style={{ fontSize: "large" }}>
                        <li>
                          Cédula de identidad y papeleta de votación (No aplica
                          para socios mayores a 65 años).
                        </li>
                        <li>Justificación de ingresos (últimos 3 meses).</li>
                        <li>Planilla de servicio básico.</li>
                        <li>Impuesto predial.</li>
                      </ul>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0">
                          Tasa de Interés
                        </h5>
                      </div>
                      <span className="ps-4" style={{ fontSize: "large" }}>
                        Accede a un crédito de hasta $10.000, con plazos de
                        hasta 48 meses y una tasa competitiva del 17%.
                      </span>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Aspectos Clave
                        </h5>
                      </div>
                      <ul style={{ fontSize: "large" }}>
                        <li>
                          Tu crédito se aprueba en un plazo de 24 a 48 horas.
                        </li>
                        <li>
                          Acceso a nuestros servicios de medicina general,
                          odontología, peluquería y un fondo mortuorio de hasta
                          $1.000
                        </li>
                        <li>
                          Puedes pagar las cuotas de tu crédito con
                          transferencias directas a tu cuenta de ahorro, a
                          través de nuestro{" "}
                          <a
                            href="https://pagos.facilito.com.ec/aplicacion/futurolamanense"
                            target="_blank"
                          >
                            Portal de Pagos Facilito
                          </a>{" "}
                          o en Bco. Pichincha Mi Vecino, usando el código{" "}
                          <b>24973</b> seguido de su número de cuenta completo.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <br />

                  <SimuladorCredito
                    codigotipocredito={"015"}
                    tipocredito={"FUTUNAVIDEÑO"}
                  />

                  <br />
                </div>
                <br />
              </section>
            </main>
          </React.Fragment>
        );
    }
}

export default FutuNavideno;