import React from "react";
import { ToastContainer } from "react-toastify";
import CreditoConsumoImg from "../../imagenes/consumoim.png";
import apiVisitas from "../../services/visitasApi";
import SimuladorCredito from "./simulador-credito";

class CreditoConsumo extends React.Component {
  agregarVisitaCreditoConsumo = async () => {
    try {
      await apiVisitas.visitas.create({ tipo: "Crédito de Consumo" });
    } catch (error) {
      console.log("error al crear visíta " + error);
    }
  };

  componentDidMount() {
    //this.agregarVisitaCreditoConsumo();
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1>Crédito de Consumo</h1>
                <h2 className="mb-3 texto-justificado">
                  Este crédito está destinado exclusivamente para la compra de
                  bienes, servicios y gastos personales no vinculados a
                  actividades productivas o comerciales. Incluye préstamos
                  prendarios sobre joyas y financiamiento para la adquisición de
                  vehículos livianos de uso particular. ¡Haz realidad tus planes
                  con nosotros!
                </h2>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img
                  src={CreditoConsumoImg}
                  className="image-credito-consumo rounded"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section id="services" className="services section-bg">
            <br /> <br />
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div
                  className="col-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Requisitos
                    </h5>
                  </div>
                  <ul style={{ fontSize: "large" }}>
                    <li>
                      {" "}
                      Cédula de identidad y papeleta de votación (No aplica para
                      socios mayores a 65 años).
                    </li>
                    <li>Justificación de ingresos (últimos 3 meses).</li>
                    <li>Planilla de servicio básico.</li>
                    <li>Impuesto predial.</li>
                  </ul>
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Tasa de Interés
                    </h5>
                  </div>
                  <span className="ps-4" style={{ fontSize: "large" }}>
                    Accede a un crédito de hasta $15.000, con plazos de hasta 60
                    meses y una tasa competitiva del 15%.
                  </span>
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Aspectos Clave
                    </h5>
                  </div>
                  <ul style={{ fontSize: "large" }}>
                    <li>Tu crédito se aprueba en un plazo de 24 a 48 horas.</li>
                    <li>
                      Acceso a nuestros servicios de medicina general,
                      odontología, peluquería y un fondo mortuorio de hasta
                      $1.000
                    </li>
                    <li>
                      Puedes pagar las cuotas de tu crédito con transferencias
                      directas a tu cuenta de ahorro, a través de nuestro{" "}
                      <a
                        href="https://pagos.facilito.com.ec/aplicacion/futurolamanense"
                        target="_blank"
                      >
                        Portal de Pagos Facilito
                      </a>{" "}
                      o en Bco. Pichincha Mi Vecino, usando el código{" "}
                      <b>24973</b> seguido de su número de cuenta completo.
                    </li>
                  </ul>
                </div>
              </div>
              <br />

              <SimuladorCredito
                codigotipocredito={"014"}
                tipocredito={"CONSUMO"}
              />

              <br />
            </div>
            <br />
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default CreditoConsumo;
