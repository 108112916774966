import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

// Imágenes del slider
import Odontologia from "./../../imagenes/odontologia.jpg";
import Peluqueria from "./../../imagenes/peluqueria-2.jpg";
import FondoMortuorio from "./../../imagenes/fondomortuorio.jpg";
import Medicina from "./../../imagenes/medicina.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../styles/Slider-swiper.css";

// Datos de beneficios
const beneficios = [
  {
    title: "Medicina General",
    image: Medicina,
    description:
      "Consultas médicas exclusivas para socios, con atención personalizada para cuidar tu salud en todo momento.",
    link: "/fondo-mortuorio",
    delay: 100,
  },
  {
    title: "Peluquería",
    image: Peluqueria,
    description:
      "Servicios exclusivos de peluquería para socios, con cortes y tratamientos de calidad. ¡Lucir bien nunca fue tan fácil!",
    link: "/peluqueria",
    delay: 200,
  },
  {
    title: "Odontología",
    image: Odontologia,
    description:
      "Atención odontológica de calidad para socios, con servicios especializados para mantener tu sonrisa saludable y radiante.",
    link: "/odontologia",
    delay: 300,
  },
  {
    title: "Fondo Mortuorio",
    image: FondoMortuorio,
    description:
      "Brinda seguridad y tranquilidad a tu familia con nuestro fondo mortuorio, un respaldo económico gratuito para socios en momentos difíciles.",
    delay: 400,
  },
];

class Beneficios extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Beneficios</h2>
              <p>
                Por ser socio activo de tu cooperativa de ahorro y crédito
                Futuro Lamanense, tienes acceso a los siguientes servicios:
              </p>
            </div>

            <Swiper
              slidesPerView={1}
              slidesPerGroupSkip={1}
              spaceBetween={20}
              centeredSlides={false}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 3,
                },
              }}
              grabCursor={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Navigation, Pagination, Autoplay]}
            >
              {beneficios.map((beneficio, index) => (
                <SwiperSlide key={index} className="slide">
                  <div
                    className="slide-item"
                    data-aos="zoom-in"
                    data-aos-delay={beneficio.delay}
                  >
                    <div className="box-products">
                      <a href={beneficio.link}>
                        <div className="icon d-flex justify-content-center">
                          <img src={beneficio.image} alt={beneficio.title} />
                        </div>
                        <h4 className="text-center mt-3 fw-bold">{beneficio.title}</h4>
                        <p className="texto-justificado text-secondary">
                          {beneficio.description}
                        </p>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Beneficios;
