import React from "react";
import logoVertical from "../../imagenes/logoblancas.png";

const directiva = [
  { name: "Moran Marmolejo Fabian Medardo", position: "Gerente" },
  { name: "Jácome Calderon Alba Verónica", position: "Presidente" },
  { name: "Escobar Chango Guido Fabian", position: "Vicepresidente" },
  { name: "Arboleda Sigcha Edwin Patricio", position: "Secretario" },
];

const consejoAdministracion = [
  { name: "Jácome Calderon Alba Verónica", position: "Vocal Principal 1" },
  { name: "Escobar Chango Guido Fabian", position: "Vocal Principal 2" },
  { name: "Palma Bravo Nelly Janeth", position: "Vocal Principal 3" },
  {
    name: "Delgado Rodríguez Hugo Bolivar",
    position: "Vocal Principal 4",
  },
  { name: "Loor Valencia Ramona Yannyne", position: "Vocal Principal 5" },
  { name: "Guzman Simanca Jesus Manuel", position: "Vocal Principal 6" },
  {
    name: "Romero Calderon Michelle Virginia",
    position: "Vocal Principal 7",
  },
  { name: "Rosado Sosa Daniel Laureno", position: "Vocal Principal 8" },
  { name: "Armas Vela Elvia Juliana", position: "Vocal Principal 9" },
  { name: "Montes Macias Galo Aristobal", position: "Vocal Suplente 1" },
  { name: "Ayala Carriel Gaudencio Vincente", position: "Vocal Suplente 2" },
  { name: "Villegas Rodriguez Laura Elizabeth", position: "Vocal Suplente 3" },
  { name: "Torres Palate Norma Marlene", position: "Vocal Suplente 4" },
  {
    name: "Chanaluisa Yanchapaxi Piedad de las Mercedes",
    position: "Vocal Suplente 5",
  },
  { name: "Navarrete Boharquez Adalaida Angela", position: "Vocal Suplente 6" },
  { name: "Zavala Marcillo Mercedes Benita", position: "Vocal Suplente 7" },
  { name: "Saavedra Velez Carlos David", position: "Vocal Suplente 8" },
  { name: "Moreno Reyes Martha Jenny", position: "Vocal Suplente 9" },
];

const consejoVigilancia = [
  { name: "Palomo Caisa Beatriz Elsa", position: "Vocal Principal 1" },
  { name: "Cabrera Reyna Alex Rodolfo", position: "Vocal Principal 2" },
  { name: "Ruiz Mena Pedro Fabian", position: "Vocal Principal 3" },
  { name: "Torres Velez Erwin Santiago", position: "Vocal Principal 4" },
  { name: "Chango Guaman Carmen Piedad", position: "Vocal Principal 5" },
  { name: "Espinoza Conforme Jesus Vicente", position: "Vocal Suplente 1" },
  { name: "Paz Maldonado Mery Elizabeth", position: "Vocal Suplente 2" },
  { name: "Suarez Lalangui Juana Felicita", position: "Vocal Suplente 3" },
  { name: "Cedeño Troya Dolores Magaly", position: "Vocal Suplente 4" },
  {
    name: "Peñaherrera Tovar Rosario Guadalupe",
    position: "Vocal Suplente 5",
  },
];

const comisiones = [
  {
    title: "Comisión de Educación",
    description:
      "Desarrolla e impulsa programas de formación y capacitación dirigidos a socios, colaboradores y la comunidad, con el objetivo de fortalecer el conocimiento financiero, cooperativo y organizacional. Además, fomenta la cultura del ahorro y la educación cooperativa mediante talleres, conferencias y actividades pedagógicas.",
  },
  {
    title: "Comisión de Asuntos Sociales y Deporte",
    description:
      "Organiza y promueve iniciativas que contribuyen al bienestar y desarrollo integral de los socios, sus familias y la comunidad en general. A través de eventos deportivos, actividades recreativas y programas de apoyo social, busca fortalecer el sentido de pertenencia y la responsabilidad social dentro de la cooperativa.",
  },
  {
    title: "Comisión de Licitación y Adquisiciones",
    description:
      "Se encarga de la planificación, evaluación y supervisión de los procesos de compra y contratación de bienes y servicios para la cooperativa. Su función principal es garantizar la transparencia, eficiencia y equidad en cada adquisición, asegurando el uso óptimo de los recursos y el cumplimiento de normativas establecidas.",
  },
];

const comites = [
  {
    title: "Comité De Administración Integral De Riesgos",
    description:
      "Es un órgano asesor del Consejo de Administración, responsable del diseño de políticas, procesos, estrategias, metodologías, sistemas de información y procedimientos para la eficiente gestión integral de riesgos.",
  },
  {
    title: "Comité de Cumplimiento",
    description:
      "Es el encargado de velar por la aplicación de las políticas y procedimientos de control para mitigar el riesgo de Lavado de Activos y Financiamiento de Delitos incluido el Terrorismo.",
  },
  {
    title: "Comité de Tecnología de información",
    description:
      "Es el encargado de supervisar la infraestructura tecnológica de la cooperativa, asegurando la implementación de soluciones innovadoras y seguras que permitan mejorar nuestros servicios.",
  },
  {
    title: "Comité de Conducta Y Ética",
    description:
      "Es el encargado de preparar y establecer procedimientos para evitar conflictos de interés, aplicar las sanciones ante los incumplimientos de principios y deberes, a fin de promover el comportamiento de excelencia profesional y personal dentro de la cooperativa.",
  },
  {
    title: "Comité de Continuidad del Negocio",
    description:
      "Se encarga de prevenir y responder ante situaciones que puedan afectar las operaciones de la cooperativa. Implementa estrategias de contingencia para garantizar la estabilidad y seguridad de los socios.",
  },
  {
    title: "Comité de Seguridad de la Información",
    description:
      "Es responsable de definir, implementar y supervisar las políticas y medidas de seguridad de la información en las entidades del sector. Su función principal es garantizar la protección de los datos y sistemas, gestionar incidentes de seguridad, asegurar el cumplimiento de normativas y promover una cultura de seguridad dentro de la organización.",
  },
  {
    title: "Comité de Inversiones",
    description:
      "Se encarga de gestionar los recursos financieros de la cooperativa, asegurando inversiones estratégicas y seguras. Analiza oportunidades para optimizar la rentabilidad y mitigar riesgos. Su objetivo es garantizar estabilidad y crecimiento para el beneficio de los socios.",
  },
];

class GobiernoCorporativo extends React.Component {
  renderTable(data) {
    return (
      <table className="table table-bordered">
        <thead>
          <tr className="table-primary">
            <th scope="col" className="text-center">
              APELLIDOS Y NOMBRES
            </th>
            <th scope="col" className="text-center">
              CARGO
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((member, index) => (
            <tr key={index}>
              <td className="text-center">{member.name}</td>
              <td className="text-center">{member.position}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderCommissions(data) {
    return data.map((commission, index) => (
      <div
        className="col-xl-6 col-md-6 mb-3 d-flex align-items-stretch"
        key={index}
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div className="icon-box">
          <h4>
            <a>{commission.title}</a>
          </h4>
          <h5 className="texto-justificado">{commission.description}</h5>
        </div>
      </div>
    ));
  }

  renderCommittees(data) {
    return data.map((comite, index) => (
      <div
        className="col-xl-6 col-md-6 mb-3 d-flex align-items-stretch"
        key={index}
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div className="icon-box">
          <h4>
            <a>{comite.title}</a>
          </h4>
          <h5 className="texto-justificado">{comite.description}</h5>
        </div>
      </div>
    ));
  }

  render() {
    return (
      <React.Fragment>
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-12 d-flex flex-column pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="text-center">
                  <h1>GOBIERNO CORPORATIVO</h1>
                </div>
                <div className="text-center pt-0">
                  <img
                    src={logoVertical}
                    className="logovertical-gerencia"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <main id="main">
          <section id="services" className="services section-bg">
            <br /> <br /> <br />
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div
                  className="col-xl-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <h5 className="texto-justificado">
                    Es el sistema por el cual una organización toma e implementa
                    decisiones para lograr sus objetivos. Consiste en la
                    definición de la estructura y funcionamiento de la
                    administración de la organización para, definir derechos y
                    deberes exigibles a los administradores y establecer los
                    mecanismos de protección de los derechos de los socios.
                  </h5>
                  <br />
                  <h5 className="texto-justificado">
                    Nuestra estructura de Gobierno Corporativo está diseñada
                    acorde a lo que dispone La Ley de Economía Popular y
                    Solidaria, lo que permite articular planes estratégicos,
                    políticas corporativas y mecanismos de control y
                    transparencia bien estructurados, entre todas las instancias
                    de la Cooperativa.
                  </h5>
                  <br />
                  <h5 className="texto-justificado">
                    La buena gestión de Gobierno Corporativo nos ha permitido
                    obtener certificaciones que reconocen la mejora en la
                    calidad de gobernanza. Entre ellas están: Certificación GIF
                    Gobernanza e inclusión.
                  </h5>
                </div>
              </div>
              <br /> <br />
              <h5 className="fw-bold">DIRECTIVA</h5>
              {this.renderTable(directiva)}
              <br /> <br />
              <h5 className="fw-bold">NÓMINA CONSEJO DE ADMINISTRACIÓN</h5>
              {this.renderTable(consejoAdministracion)}
              <br /> <br />
              <h5 className="fw-bold">NÓMINA CONSEJO DE VIGILANCIA</h5>
              {this.renderTable(consejoVigilancia)}
            </div>
            <br /> <br /> <br /> <br />
          </section>

          <section id="services" className="services">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Comisiones por Normativa</h2>
              </div>
              <div className="row">{this.renderCommissions(comisiones)}</div>
            </div>
          </section>

          <section id="services" className="services">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Comités por Normativa</h2>
              </div>
              <div className="row">{this.renderCommittees(comites)}</div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default GobiernoCorporativo;
