import React from "react";
import apiMensajes from "../../services/mensajesApi";
import Mensajes from "../../models/Mensajes";
import { ToastContainer, toast } from 'react-toastify';
import ContactosPng from "../../imagenes/contact.png";
import ContactosDerechaPng from "../../imagenes/Contacto.png";
import Sucursales from "../components/sucursales";
import Facebook from "../../imagenes/Facebook.png";
import Tiwitter from "../../imagenes/Twitter.png";
import Instagram from "../../imagenes/Instagram.png";
import Youtube from "../../imagenes/Youtube.png";

class Contactos extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    state = {
        form: {
            'nombre2': '',
            'email2': '',
            'asunto2': '',
            'mensaje2': ''
        }
    }

    limpiarForm = () => {
        this.setState({
            form: {
                'nombre2': '',
                'email2': '',
                'asunto2': '',
                'mensaje2': ''
            }
        })
    }

    agregarMensaje = async (e) => {
        e.preventDefault();
        try {
            let mensaje = new Mensajes(null, this.state.form.nombre2, this.state.form.email2, this.state.form.asunto2, this.state.form.mensaje2);
            await apiMensajes.mensajes.create(mensaje);
            this.limpiarForm();
            toast.success("Tu mensaje se a enviado correctamente");
        } catch (error) {
            toast.error("error " + error);
        }
    }

    handleChange = (e) => {
        this.setState({
            form: {
                ... this.state.form,
                [e.target.name]: e.target.value,
            }
        })
    }

    render() {
        return (
          <React.Fragment>
            <ToastContainer />
            <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h1>Contactos</h1>
                    <h2>
                      ¡Estamos aquí para ayudarte! Contáctanos para resolver tus
                      dudas, recibir asesoría o conocer más sobre nuestros
                      servicios. ¡Tu futuro empieza hoy!
                    </h2>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2 hero-img"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src={ContactosPng}
                      className="img-fluid animated rounded w-75"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="contact" className="contact section-bg">
                <br /> <br />
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div
                      className="col-xl-6 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <form
                        className="php-email-form"
                        onSubmit={this.agregarMensaje}
                      >
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label>Tu Nombre</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={this.handleChange}
                              name="nombre2"
                              value={this.state.form.nombre2}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>Tu Email</label>
                            <input
                              type="email"
                              className="form-control"
                              onChange={this.handleChange}
                              name="email2"
                              value={this.state.form.email2}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Asunto</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={this.handleChange}
                            name="asunto2"
                            value={this.state.form.asunto2}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label>Mensaje</label>
                          <textarea
                            className="form-control"
                            rows="10"
                            onChange={this.handleChange}
                            name="mensaje2"
                            value={this.state.form.mensaje2}
                            required
                          ></textarea>
                        </div>
                        <div className="text-center">
                          <button type="submit">Enviar Mensaje</button>
                        </div>
                      </form>
                    </div>
                    <div
                      className="col-xl-6 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <img
                        src={ContactosDerechaPng}
                        className="img-fluid animated rounded "
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <br />
              </section>
              {/* <Sucursales /> */}
              <br />
              <br />
              <br />
              {/* <section id="services" className="services section-bg">
                <div className="container" data-aos="fade-up">
                  <div className="section-title">
                    <h2>Nuestras Redes Sociales</h2>
                  </div>

                  <div className="row">
                    <div
                      className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
                      data-aos="zoom-in"
                      data-aos-delay="400"
                    >
                      <div className="icon-box">
                        <div className="icon">
                          <img src={Tiwitter} className="w-50 rounded" />
                        </div>
                        <h4 className="pt-4">
                          <a href="https://twitter.com/FLM_EC" target="_blank">
                            Twitter
                          </a>
                        </h4>
                      </div>
                    </div>

                    <div
                      className="col-xl-3 col-md-6 d-flex align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="icon-box">
                        <div className="icon">
                          <img src={Facebook} className="w-50 rounded" />
                        </div>
                        <h4>
                          <a
                            href="https://www.facebook.com/COACFUTLAMANENSE"
                            target="_blank"
                          >
                            Facebook
                          </a>
                        </h4>
                      </div>
                    </div>

                    <div
                      className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
                      data-aos="zoom-in"
                      data-aos-delay="300"
                    >
                      <div className="icon-box">
                        <div className="icon">
                          <img src={Instagram} className="w-50 rounded" />
                        </div>
                        <h4>
                          <a
                            href="https://www.instagram.com/futuro_lamanense/"
                            target="_blank"
                          >
                            Instagram
                          </a>
                        </h4>
                      </div>
                    </div>

                    <div
                      className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
                      data-aos="zoom-in"
                      data-aos-delay="400"
                    >
                      <div className="icon-box">
                        <div className="icon">
                          <img src={Tiwitter} className="w-50 rounded" />
                        </div>
                        <h4 className="pt-4">
                          <a href="https://twitter.com/FLM_EC" target="_blank">
                            Tik Tok
                          </a>
                        </h4>
                      </div>
                    </div>

                    <div
                      className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
                      data-aos="zoom-in"
                      data-aos-delay="400"
                    >
                      <div className="icon-box">
                        <div className="icon">
                          <img src={Youtube} className="w-50 rounded" />
                        </div>
                        <h4 className="pt-2">
                          <a
                            href="https://www.youtube.com/channel/UCWy9n8Y23_QQern3sZVX7oA"
                            target="_blank"
                          >
                            YouTube
                          </a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}
            </main>
          </React.Fragment>
        );
    }
}

export default Contactos;