import React from "react";
import BannerSeguridadInformacion from "./../../imagenes/seguridad-informacion.jpg";

class SeguridadInformacion extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="cta" className="cta">
          <div className="container" data-aos="zoom-in">
            <div className="row">
              <div className="col-12 text-center">
                <img
                  src={BannerSeguridadInformacion}
                  className="w-100 rounded"
                />
              </div>
              {/* <div className="col-12 text-center">
                <p className="mt-3">
                  <a
                    className="cta-btn align-middle"
                    href="/codigo-etica#lavadoActivos"
                  >
                    Más información
                  </a>
                </p>
              </div> */}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default SeguridadInformacion;
