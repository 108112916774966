import React from "react";
import apiSimulador from "../../services/simuladorApi";
import urlGlobal from "../../services/urlGlobal";
import { toast } from "react-toastify";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "../../styles/Simulador.css";

class SimuladorCredito extends React.Component {
  state = {
    segmentointerno: [],
    subcalificacion: [],
    cuotaMinima: 0,
    cuotaMaxima: 0,
    form: {
      segmentointerno: "",
      subcalificacion: "",
      monto: "",
      diafijo: "1",
      cuotas: "",
    },
    montoMinimo: 500, // Valor por defecto
    montoMaximo: 0,
    loading: "false",
    tablapresuntiva: [],
    resultcredito: {
      FechaAdjudicacion: "",
      FechaVencimiento: "",
      Tea: "",
      ValorTasa: "",
      DescripcionCondicionTablaAmortizacion: "",
    },
    calculo: "false",
  };

  limpiar = () => {
    this.setState({
      form: {
        segmentointerno: "",
        subcalificacion: "",
        monto: "",
        diafijo: "1",
        cuotas: "",
      },
      loading: "false",
      tablapresuntiva: [],
      resultcredito: {
        FechaAdjudicacion: "",
        FechaVencimiento: "",
        Tea: "",
        ValorTasa: "",
        DescripcionCondicionTablaAmortizacion: "",
        DescripcionTipoPrestamo: "",
      },
      calculo: "false",
      subcalificacion: [],
    });
  };

  getSegmentointerno = async (coditoTipoCredito) => {
    try {
      const data = await apiSimulador.simulador.segmentointerno({
        codigo: coditoTipoCredito,
      });
      console.log("Segmento Interno: ", data);
      this.setState({ segmentointerno: data.segmentointernos });
    } catch (error) {
      console.log(error);
    }
  };

  getSubcalificacion = async (secuencial) => {
    try {
      const data = await apiSimulador.simulador.subcalificacion(secuencial);
      console.log("Subcalificacion: ", data);
      this.setState({ subcalificacion: data.subcalificacion });
    } catch (error) {
      console.log(error);
    }
  };

  getMesesPlazos = (tipoPrestamo) => {
    let cuotaMinima = 0;
    let cuotaMaxima = 0;

    switch (tipoPrestamo) {
      case "015":
        cuotaMinima = 12;
        cuotaMaxima = 144;
        break;
      case "010":
        cuotaMinima = 12;
        cuotaMaxima = 144;
        break;
      case "014":
        cuotaMinima = 12;
        cuotaMaxima = 60;
        break;
      default:
        cuotaMinima = 0;
        cuotaMaxima = 0;
        break;
    }

    this.setState({
      cuotaMinima: cuotaMinima,
      cuotaMaxima: cuotaMaxima,
    });
  };

  mascarafecha = (fecha) => {
    var event = new Date(fecha);
    let date = JSON.stringify(event);
    date = date.slice(1, 11);
    return date;
  };

  segmentoInternoSubCalificacion = (tipoPrestamo) => {
    let segmento = "";
    let subcalificacion = "";

    switch (tipoPrestamo) {
      case "015":
        segmento = "150";
        // 0402 (Microcredito) 0468 (Futu-navideño) 0465 (Violeta) 0461 (FutuSalud - FutuAuto) 0481 (FutuConfianza) 0471 (FutuAniversario) 0484 (Premium)
        if (this.props.tipocredito) {
          const nombreLower = this.props.tipocredito.toLowerCase();

          if (nombreLower.includes("microcrédito")) {
            subcalificacion = "0402";
          } else if (nombreLower.includes("futunavideño")) {
            subcalificacion = "0468";
          } else if (nombreLower.includes("violeta")) {
            subcalificacion = "0465";
          } else if (nombreLower.includes("futuauto")) {
            subcalificacion = "0461";
          } else if (nombreLower.includes("futusalud")) {
            subcalificacion = "0461";
          } else if (nombreLower.includes("futuconfianza")) {
            subcalificacion = "0481";
          } else if (nombreLower.includes("futuaniversario")) {
            subcalificacion = "0471";
          } else if (nombreLower.includes("premium")) {
            subcalificacion = "0484";
          } else if (nombreLower.includes("ecofuturo")) {
            subcalificacion = "0203";
          }
        } else {
          subcalificacion = "0402";
        }
        break;
      case "010":
        segmento = "166";
        subcalificacion = "0301";
        break;
      case "014":
        segmento = "131";
        subcalificacion = "0203";
        break;
      default:
        segmento = "";
        subcalificacion = "";
        break;
    }

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        segmentointerno: segmento,
        subcalificacion: subcalificacion,
      },
    }));
  };

  montoTipoPrestamo = (tipoPrestamo) => {
    let montoMaximo = 0;
    let cuotaMax = 0;
    switch (tipoPrestamo) {
      case "015":
        if (this.props.tipocredito) {
          const nombreLower = this.props.tipocredito.toLowerCase();

          if (nombreLower.includes("microcrédito")) {
            montoMaximo = 80000;
            cuotaMax = 144;
          } else if (nombreLower.includes("futunavideño")) {
            montoMaximo = 10000;
            cuotaMax = 48;
          } else if (nombreLower.includes("violeta")) {
            montoMaximo = 15000;
            cuotaMax = 60;
          } else if (nombreLower.includes("futuauto")) {
            montoMaximo = 80000;
            cuotaMax = 102;
          } else if (nombreLower.includes("futusalud")) {
            montoMaximo = 2000;
            cuotaMax = 24;
          } else if (nombreLower.includes("futuconfianza")) {
            montoMaximo = 10000;
            cuotaMax = 36;
          } else if (nombreLower.includes("futuaniversario")) {
            montoMaximo = 10000;
            cuotaMax = 36;
          } else if (nombreLower.includes("premium")) {
            montoMaximo = 80000;
            cuotaMax = 144;
          } else if (nombreLower.includes("ecofuturo")) {
            montoMaximo = 80000;
            cuotaMax = 102;
          }
        } else {
          montoMaximo = 80000;
          cuotaMax = 144;
        }
        break;
      case "010":
        montoMaximo = 80000;
        cuotaMax = 144;
        break;
      case "014":
        montoMaximo = 15000;
        cuotaMax = 60;
        break;
      default:
        montoMaximo = 0;
        cuotaMax = 0;
        break;
    }

    this.setState({
      montoMinimo: 500, // Valor fijo
      montoMaximo: montoMaximo,
      cuotaMaxima: cuotaMax,
    });
  };

  sumaTotalInteres = (data) => {
    const totalValorIntereses = data.reduce((acumulador, cuota) => {
      return acumulador + parseFloat(cuota.ValorInteres);
    }, 0);

    const totalValorOtros = data.reduce((acumulador, cuota) => {
      return acumulador + parseFloat(cuota.ValorOtros);
    }, 0);

    return Number((totalValorIntereses + totalValorOtros).toFixed(2));
  };

  sumaTotalAPagar = () => {
    const monto = Number(this.state.form.monto);
    return (monto + this.sumaTotalInteres(this.state.tablapresuntiva)).toFixed(
      2
    );
  };

  componentDidMount() {
    //Recibo el parametro del componente padre
    let codigoTipoCredito = this.props.codigotipocredito;
    this.segmentoInternoSubCalificacion(codigoTipoCredito);
    this.montoTipoPrestamo(codigoTipoCredito);
    this.getMesesPlazos(codigoTipoCredito);
    //----
    // this.getSegmentointerno(codigoTipoCredito);
  }

  handleChange = (e) => {
    if (e.target.name === "segmentointerno") {
      this.getSubcalificacion(e.target.value);
    }
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  consultarCredito = async () => {
    const { monto, cuotas } = this.state.form;
    const { montoMinimo, montoMaximo, cuotaMinima, cuotaMaxima } = this.state;

    if (!monto || !cuotas) {
      toast.error("Tienes que llenar los campos.");
      this.setState({ calculo: "false" });
      return;
    }

    if (monto < montoMinimo || monto > montoMaximo) {
      toast.error(`El monto debe estar entre ${montoMinimo} y ${montoMaximo}.`);
      this.setState({ calculo: "false" });
      return;
    }

    if (cuotas < cuotaMinima || cuotas > cuotaMaxima) {
      toast.error(
        `El número de cuotas debe estar entre ${cuotaMinima} y ${cuotaMaxima} meses.`
      );
      this.setState({ calculo: "false" });
      return;
    }

    this.setState({ loading: "true" });
    try {
      const data = await apiSimulador.simulador.consultarCredito(
        this.props.codigotipocredito,
        "1",
        this.state.form.segmentointerno,
        this.state.form.subcalificacion,
        urlGlobal.fechaActual(),
        monto,
        "1",
        cuotas,
        30
      );

      console.log(data);

      this.setState({
        loading: "false",
        tablapresuntiva:
          data.result.DevuelveTablaPresuntivaParaSitioResult
            .DevuelveTablaPresuntivaParaImpresionMS.ItemsTabla
            .TablaPagos_PorCuotas,
        resultcredito: {
          FechaAdjudicacion:
            data.result.DevuelveTablaPresuntivaParaSitioResult
              .DevuelveTablaPresuntivaParaImpresionMS.FechaAdjudicacion,
          FechaVencimiento:
            data.result.DevuelveTablaPresuntivaParaSitioResult
              .DevuelveTablaPresuntivaParaImpresionMS.FechaVencimiento,
          Tea: data.result.DevuelveTablaPresuntivaParaSitioResult
            .DevuelveTablaPresuntivaParaImpresionMS.Tea,
          ValorTasa:
            data.result.DevuelveTablaPresuntivaParaSitioResult
              .DevuelveTablaPresuntivaParaImpresionMS.ValorTasa,
          DescripcionCondicionTablaAmortizacion:
            data.result.DevuelveTablaPresuntivaParaSitioResult
              .DevuelveTablaPresuntivaParaImpresionMS
              .DescripcionCondicionTablaAmortizacion,
          DescripcionTipoPrestamo: this.props.tipocredito,
        },
        calculo: "true",
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  descargarFormulario = () => {
    const contenido = document.getElementById("tablaPresuntivaExport"); // Seleccionamos el contenedor que se quiere exportar

    if (contenido) {
      // Usamos html2canvas para capturar el contenido visible
      html2canvas(contenido, { scale: 1 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png"); // Convertimos la captura en imagen
        const doc = new jsPDF("portrait", "mm", "a4"); // Crear documento PDF

        // Añadir la imagen capturada al documento PDF
        doc.addImage(imgData, "PNG", 5, 5, 200, 200); // Puedes ajustar las dimensiones de la imagen

        // Guardar el PDF
        doc.save("Tabla-Presuntiva.pdf");
      });
    } else {
      console.error("No se encontró el contenido para exportar");
    }

    this.mostrarDialogoPrint = false; // Cerrar el diálogo de vista previa
  };

  // consultarCredito = async () => {
  //   console.log(this.state);
  //   if (
  //     this.state.form.segmentointerno === "" ||
  //     this.state.form.subcalificacion === "" ||
  //     this.state.form.monto === "" ||
  //     this.state.form.diafijo === "" ||
  //     this.state.form.cuotas === ""
  //   ) {
  //     toast.error("Tienes que llenar todos los campos");
  //   } else {
  //     this.setState({ loading: "true" });
  //     try {
  //       const data = await apiSimulador.simulador.consultarCredito(
  //         this.props.codigotipocredito,
  //         "1",
  //         this.state.form.segmentointerno,
  //         this.state.form.subcalificacion,
  //         urlGlobal.fechaActual(),
  //         this.state.form.monto,
  //         this.state.form.diafijo,
  //         this.state.form.cuotas,
  //         30
  //       );
  //       console.log(data);
  //       this.setState({
  //         loading: "false",
  //         tablapresuntiva:
  //           data.result.DevuelveTablaPresuntivaParaSitioResult
  //             .DevuelveTablaPresuntivaParaImpresionMS.ItemsTabla
  //             .TablaPagos_PorCuotas,
  //         resultcredito: {
  //           FechaAdjudicacion:
  //             data.result.DevuelveTablaPresuntivaParaSitioResult
  //               .DevuelveTablaPresuntivaParaImpresionMS.FechaAdjudicacion,
  //           FechaVencimiento:
  //             data.result.DevuelveTablaPresuntivaParaSitioResult
  //               .DevuelveTablaPresuntivaParaImpresionMS.FechaVencimiento,
  //           Tea: data.result.DevuelveTablaPresuntivaParaSitioResult
  //             .DevuelveTablaPresuntivaParaImpresionMS.Tea,
  //           ValorTasa:
  //             data.result.DevuelveTablaPresuntivaParaSitioResult
  //               .DevuelveTablaPresuntivaParaImpresionMS.ValorTasa,
  //           DescripcionCondicionTablaAmortizacion:
  //             data.result.DevuelveTablaPresuntivaParaSitioResult
  //               .DevuelveTablaPresuntivaParaImpresionMS
  //               .DescripcionCondicionTablaAmortizacion,
  //           DescripcionTipoPrestamo:
  //             data.result.DevuelveTablaPresuntivaParaSitioResult
  //               .DevuelveTablaPresuntivaParaImpresionMS.DescripcionTipoPrestamo,
  //         },
  //         calculo: "true",
  //       });
  //     } catch (error) {
  //       console.log(error);
  //       this.setState({ loading: "false" });
  //     }
  //   }
  // };

  mostrarCalculo = () => {
    if (this.state.calculo === "true") {
      return (
        <>
          <div id="tablaPresuntivaExport" className="bg-white p-2 rounded">
            <br />
            <div className="text-center">
              <h4>TABLA PRESUNTIVA DE PAGOS</h4>
            </div>
            <br />
            <div className="row font-letter">
              <div className="col-lg-6 text-center">
                <div className="row">
                  <div className="col-md-6 text-lg-end">
                    {" "}
                    <b>Tipo de prestamo:</b>{" "}
                  </div>
                  <div className="col-md-6">
                    {" "}
                    {this.state.resultcredito.DescripcionTipoPrestamo}{" "}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-lg-end">
                    {" "}
                    <b>No. cuotas:</b>{" "}
                  </div>
                  <div className="col-md-6"> {this.state.form.cuotas} </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-6 text-lg-end">
                    {" "}
                    <b>Frecuencia de pago:</b>{" "}
                  </div>
                  <div className="col-lg-6"> 30 </div>
                </div> */}
                <div className="row">
                  <div className="col-md-6 text-lg-end">
                    {" "}
                    <b>Monto solicitado:</b>{" "}
                  </div>
                  <div className="col-md-6"> ${this.state.form.monto} </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-lg-end">
                    {" "}
                    <b>Tasa de interés:</b>{" "}
                  </div>
                  <div className="col-md-6">
                    {" "}
                    {this.state.resultcredito.ValorTasa}%
                  </div>
                </div>
              </div>
              <div className="col-lg-6 text-center">
                <div className="row">
                  <div className="col-md-6 text-lg-end">
                    {" "}
                    <b>Fecha de adjudicación:</b>{" "}
                  </div>
                  <div className="col-md-6">
                    {" "}
                    {this.mascarafecha(
                      this.state.resultcredito.FechaAdjudicacion
                    )}{" "}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-lg-end">
                    {" "}
                    <b>Fecha de vencimiento:</b>{" "}
                  </div>
                  <div className="col-md-6">
                    {" "}
                    {this.mascarafecha(
                      this.state.resultcredito.FechaVencimiento
                    )}{" "}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-lg-end">
                    {" "}
                    <b>Total de interés</b>{" "}
                  </div>
                  <div className="col-md-6">
                    {" "}
                    ${this.sumaTotalInteres(this.state.tablapresuntiva)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-lg-end">
                    {" "}
                    <b>Total a pagar:</b>{" "}
                  </div>
                  <div className="col-md-6"> ${this.sumaTotalAPagar()}</div>
                </div>
              </div>
            </div>
            <br />
            <div className="table-responsive d-flex justify-content-md-center">
              <table className="table table-bordered border-black text-center w-75">
                <thead>
                  <tr>
                    <th scope="col">Cuota</th>
                    <th scope="col">Fecha de pago</th>
                    <th scope="col">Capital</th>
                    <th scope="col">Interés</th>
                    <th scope="col" style={{ width: "10%" }}>
                      Seguro Desgravamen
                    </th>
                    <th scope="col">Total</th>
                    <th scope="col">Saldo capital</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.tablapresuntiva.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{data.NumeroCuota}</td>
                        <td>{this.mascarafecha(data.FechaPago)}</td>
                        <td>${data.ValorCapital}</td>
                        <td>${data.ValorInteres}</td>
                        <td>${data.ValorOtros}</td>
                        <td>${data.ValorTotal}</td>
                        <td>${data.SaldoCapital}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="text-end mt-3">
            <button className="btnn" onClick={() => this.descargarFormulario()}>
              <i className="bi bi-file-earmark-arrow-down"></i> Descargar
            </button>
          </div>
        </>
      );
    } else {
    }
  };

  loadingShow = () => {
    if (this.state.loading === "true") {
      return (
        <>
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="spinner-border"
              role="status"
              aria-hidden="true"
            ></div>
          </div>
        </>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div
            className="col-12 align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="alert alert-primary" role="alert">
              <h5 className="texto-justificado mb-0 fw-bold">
                Simulador de Crédito
              </h5>
            </div>

            <div className="container">
              <div className="row justify-content-evenly">
                {/* <div className="col-lg-4 pt-4">
                  <h6 className="ms-1">Segmento interno</h6>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={this.handleChange}
                    name="segmentointerno"
                    value={this.state.form.segmentointerno}
                    required
                  >
                    <option value="">...</option>
                    {this.state.segmentointerno.map((data, index) => {
                      return (
                        <option value={data.secuencial} key={index + 1}>
                          {data.nombre}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-lg-4 pt-4">
                  <h6 className="ms-1">Subcalificación</h6>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={this.handleChange}
                    name="subcalificacion"
                    value={this.state.form.subcalificacion}
                    required
                  >
                    <option value="">...</option>
                    {this.state.subcalificacion.map((data, index) => {
                      if (data.codigo != "0464" && data.codigo != "0465") {
                        return (
                          <option value={data.codigo} key={index + 1}>
                            {data.nombre}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div> */}
                <div className="col-lg-3 col-md-4 pt-3">
                  <h6 className="ps-1">Monto</h6>
                  <input
                    className="form-control text-center"
                    type="number"
                    onChange={this.handleChange}
                    name="monto"
                    value={this.state.form.monto}
                    required
                  />
                </div>
                {/* <div className="col-lg-2 pt-4">
                  <h6 className="ms-1">Día fijo</h6>
                  <input
                    className="form-control"
                    type="text"
                    onChange={this.handleChange}
                    name="diafijo"
                    value={this.state.form.diafijo}
                    required
                  />
                </div> */}
                <div className="col-lg-3 col-md-4 pt-3">
                  <h6 className="ps-1">Número de cuotas</h6>
                  <input
                    className="form-control text-center"
                    type="number"
                    onChange={this.handleChange}
                    name="cuotas"
                    value={this.state.form.cuotas}
                    required
                  />
                </div>
                <div className="col-lg-4 col-md-8 pt-4 d-flex justify-content-center">
                  <button
                    className="btnndanger me-3"
                    onClick={() => this.limpiar()}
                  >
                    Limpiar
                  </button>
                  <button
                    className="btnn"
                    onClick={() => this.consultarCredito()}
                  >
                    Calcular
                  </button>
                  {/* <table className="custom-table">
                    <thead>
                      <tr>
                        <th>Monto mínimo</th>
                        <th>Monto máximo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>${this.state.montoMinimo}</td>
                        <td>${this.state.montoMaximo}</td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
              </div>
              <br />
              {/* <div className="border-top text-end mt-2 mb-3">
                <button
                  className="btnndanger me-3"
                  onClick={() => this.limpiar()}
                >
                  Limpiar
                </button>
                <button
                  className="btnn"
                  onClick={() => this.consultarCredito()}
                >
                  Calcular
                </button>
              </div> */}
              {this.loadingShow()}
              {this.mostrarCalculo()}
            </div>
            <br />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SimuladorCredito;
