import React from "react";
import CuentaAhorros from "../../imagenes/ahorro.png";

class LeyProteccionDatosEcuador extends React.Component {
    render() {
        return (
          <React.Fragment>
            <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h1>
                      Ley Orgánica de Protección de Datos Personales en Ecuador
                    </h1>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2 hero-img"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src={CuentaAhorros}
                      className="img-fluid animated rounded w-75"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="services" className="services section-bg">
                <br /> <br />
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0">INTRODUCCIÓN</h5>
                      </div>
                      <p className="ps-4">
                        En Ecuador el artículo 66, numeral 19 de la Constitución
                        de la República, establece “el derecho a la protección
                        de datos de carácter personal, que incluye el acceso y
                        la decisión sobre información y datos de este carácter,
                        así como su correspondiente protección. La recolección,
                        archivo, procesamiento, distribución o difusión de estos
                        datos o información requerirán la autorización del
                        titular o el mandato de la ley”.
                      </p>
                      <p className="ps-4">
                        Al amparo de esta norma, la Dirección Nacional de
                        Registros Públicos trabajó en la propuesta del proyecto
                        de Ley de Protección de Datos Personales, ya que una Ley
                        de Protección de Datos Personales es necesaria en un
                        mundo hiperconectado, pues habilita la confianza
                        digital.
                      </p>
                      <p className="ps-4">
                        Con la Ley de Protección de Datos Personales, se busca
                        cuidar a las personas titulares de los datos, para que
                        ellas puedan decidir a quién entregar su información
                        personal porque confían en los proveedores de servicios
                        digitales.
                      </p>
                      <br />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0">
                          Objeto y finalidad
                        </h5>
                      </div>
                      <p className="ps-4">
                        Es garantizar el ejercicio del derecho a la protección
                        de datos personales, que incluye el acceso y decisión
                        sobre información y datos de este carácter, asi como su
                        correspondiente protección. Para dicho efecto regula,
                        prevé y desarroll principios, derechos, obligaciones y
                        mecanismos de tutela.
                      </p>
                      <p className="ps-4"></p>
                      <br />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0">
                          RESOLUCIÓN NO. 009-NG-DINARDAP-2021
                        </h5>
                      </div>
                      <p className="ps-4 text-justify">
                        En el Registro Oficial del 5 de junio de 2021 se publicó
                        la Resolución No. 009-NG-DINARDAP-2021, que establece
                        los criterios al tratamiento de datos personales en el
                        Sistema Nacional de Registro de Datos Públicos y que
                        será de cumplimiento obligatorio para todas las
                        entidades que conforman el sistema.
                      </p>
                      <p className="ps-4 text-justify">
                        La Resolución tiene alcance a los servicios que son
                        parte del Sinardap y que estarán sujetos a la normativa
                        en materia de protección de datos personales.
                      </p>
                      <p className="ps-4 text-justify">
                        La Resolución contempla también, las etapas asociadas al
                        intercambio de información entre entidades fuentes y
                        consumidoras de los servicios del Sinardap, es decir,
                        considera la estandarización y construcción del dato, la
                        protección de la información, la interconexión y
                        seguridad; y, la mejora continua.
                      </p>
                      <p className="ps-4 text-justify">
                        Además, observa la obligatoriedad de cumplimiento de los
                        principios y derechos que rigen el tratamiento de los
                        datos personales. Entre los principios señalados en la
                        presente resolución se incluye la juridicidad, lealtad y
                        transparencia, legitimidad, consentimiento, finalidad,
                        pertinencia, proporcionalidad, confidencialidad,
                        calidad, conservación, seguridad, responsabilidad
                        pro-activa y demostrada; y aplicación favorable al
                        titular.
                      </p>
                      <p className="ps-4 text-justify">
                        En cuanto a los Derechos se refiere a “aquellos propios
                        del contenido esencial del derecho a la protección de
                        datos personales, así como, de aquellos determinados en
                        la Constitución de la República, la Ley Orgánica del
                        Sistema Nacional de Registro de Datos Públicos y su
                        Reglamento de aplicación”.
                      </p>
                      <br />
                    </div>
                  </div>

                  <br />
                </div>
                <br />
              </section>
            </main>
          </React.Fragment>
        );
    }
}

export default LeyProteccionDatosEcuador;