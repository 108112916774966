import React from "react";
import OdontologiaImg from "../../imagenes/odontologia.png";
import apiVisitas from "../../services/visitasApi";

class Odontologia extends React.Component {

    agregarVisitaOdontologia = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Odontología" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        this.agregarVisitaOdontologia();
    }

    render() {
        return (
          <React.Fragment>
            <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h1>Servicio de Odontología</h1>
                    <h2>
                      ¡Queremos verte bien! Aprovecha los beneficios de formar
                      parte de la familia Futuro Lamanense.
                    </h2>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src={OdontologiaImg}
                      className="img-fluid rounded w-75"
                      alt="img-odontologiaImg"
                    />
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="services" className="services section-bg">
                <br /> <br />
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div
                      className="col-12 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Ofrecemos
                        </h5>
                      </div>
                      <ul style={{ fontSize: "large" }}>
                        <li>Análisis clínico y diagnóstico</li>
                        <li>Profilaxis</li>
                        <li>Fluorizaciones</li>
                        <li>Sellantes (niños)</li>
                        <li>Obturaciones</li>
                        <li>Restauraciones</li>
                        <li>Exodoncias</li>
                      </ul>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-12 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Agenda tu cita
                        </h5>
                      </div>
                      <span className="ps-1" style={{ fontSize: "large" }}>
                        Ahora puedes agendar tu cita de manera fácil y rápida a
                        través de nuestra{" "}
                        <a
                          href="https://bit.ly/billeteradigital"
                          target="_blank"
                        >
                          aplicación móvil
                        </a>{" "}
                        o{" "}
                        <a
                          href="https://bdfl.futurolamanense.fin.ec/login"
                          target="_blank"
                        >
                          banca web
                        </a>
                        , fácil, rápido y seguro.
                      </span>
                    </div>
                  </div>
                  <br />
                </div>
                <br />
              </section>
            </main>
          </React.Fragment>
        );
    }
}

export default Odontologia;