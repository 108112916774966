import React from "react";
import CuentaAhorros from "../../imagenes/ahorro.png";
import apiVisitas from "../../services/visitasApi";

class CuentaAhorrosPage extends React.Component {

    agregarVisitaCuentaAhorros = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Cuenta de Ahorro" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        this.agregarVisitaCuentaAhorros();
    }

    render() {
        return (
          <React.Fragment>
            <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h1>Ahorro a la Vista</h1>
                    <h2 className="mb-3 texto-justificado">
                      Apertura tu cuenta de ahorros y forma parte de nuestra
                      familia.
                    </h2>
                    <h2 className="texto-justificado">
                      Te ofrecemos la posibilidad de administrar tu dinero de
                      forma segura generando intereses a tu favor, esta cuenta
                      te permite efectuar transferencias y retiros de tu dinero
                      desde nuestras agencias o en cualquier cajero automático.
                    </h2>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src={CuentaAhorros}
                      className="w-75 img-fluid rounded"
                      alt="img-cuentaAhorros"
                    />
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="services" className="services section-bg">
                <br /> <br />
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div
                      className="col-12 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Requisitos
                        </h5>
                      </div>
                      <ul style={{ fontSize: "large" }}>
                        <li>Copia a color de cédula y papeleta de votación.</li>
                        <li>
                          Copia de la última planilla de cualquier servicio
                          básico.
                        </li>
                        <li>Correo electrónico</li>
                      </ul>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-12 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Monto de apertura
                        </h5>
                      </div>
                      <span style={{ fontSize: "large" }}>
                        El monto requerido para la apertura de la cuenta es de
                        USD $35.00
                      </span>
                      <br />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-12 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Beneficios
                        </h5>
                      </div>
                      <ul style={{ fontSize: "large" }}>
                        <li>
                          Gestiona tu cuenta desde cualquier lugar con nuestra{" "}
                          <a
                            href="https://play.google.com/store/apps/details?id=futuro.lamanence.fin"
                            target="_blank"
                          >
                            aplicación móvil
                          </a>{" "}
                          o{" "}
                          <a
                            href="https://bdfl.futurolamanense.fin.ec/login"
                            target="_blank"
                          >
                            banca web
                          </a>
                          , fácil, rápido y seguro.
                        </li>
                        <li>
                          Acceso a nuestros servicios de medicina general,
                          odontología, peluquería y un fondo mortuorio de hasta
                          $1.000 (previo un débito anual de $10.00)
                        </li>
                      </ul>
                    </div>
                  </div>
                  <br />
                </div>
                <br />
              </section>
            </main>
          </React.Fragment>
        );
    }
}

export default CuentaAhorrosPage;