import React from "react";
import AhorroProgramadoImg from "../../imagenes/programadoahorro.png";
import apiVisitas from "../../services/visitasApi";

class AhorroProgramado extends React.Component {

    agregarVisitaAhorroProgramado = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Ahorro Programado" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        this.agregarVisitaAhorroProgramado();
    }

    render() {
        return (
          <React.Fragment>
            <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h1>Ahorro Programado</h1>
                    <h2 className="texto-justificado">
                      Aporta una cuota fija mensual según tu capacidad y decide
                      el destino de tu ahorro. Con un compromiso mínimo de un
                      año, al final recibirás el capital acumulado junto con los
                      intereses generados. ¡Empieza hoy y da el primer paso
                      hacia tu bienestar financiero!"
                    </h2>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src={AhorroProgramadoImg}
                      className="image-cuenta-ahorros-programado rounded "
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="services" className="services section-bg">
                <br /> <br />
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div
                      className="col-12 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Monto de Apertura
                        </h5>
                      </div>
                      <ul style={{ fontSize: "large" }}>
                        <li>
                          El monto para la apertura de la cuenta es de USD $5.00
                        </li>
                        <li>
                          En caso de ahorro la base mínima es de USD $10.00
                        </li>
                      </ul>
                    </div>
                    <div
                      className="col-12 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Que ofrece
                        </h5>
                      </div>
                      <span style={{ fontSize: "large" }}>
                        Mayor rentabilidad, con la mejor tasa de interés del
                        mercado, producimos tu dinero de forma rápida, segura y
                        confiable. Invierte para un futuro mejor.
                      </span>
                    </div>
                    <div
                      className="col-12 align-items-stretch mt-4"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Beneficios
                        </h5>
                      </div>
                      <ul style={{ fontSize: "large" }}>
                        <li>
                          Gestiona tu cuenta desde cualquier lugar con nuestra{" "}
                          <a
                            href="https://play.google.com/store/apps/details?id=futuro.lamanence.fin"
                            target="_blank"
                          >
                            aplicación móvil
                          </a>{" "}
                          o{" "}
                          <a
                            href="https://bdfl.futurolamanense.fin.ec/login"
                            target="_blank"
                          >
                            banca web
                          </a>
                          , fácil, rápido y seguro.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <br />
                </div>
                <br />
              </section>
            </main>
          </React.Fragment>
        );
    }
}

export default AhorroProgramado;