import { format, parseISO } from "date-fns";

const UrlGlobal = {
  urlApi:
    "https://apirest.futurolamanense.fin.ec/api-futuro-informativa/public",
  urlArchivos: "https://apirest.futurolamanense.fin.ec/archivosPlantillas/",
  urlApiGolang: "https://sistemflm.futurolamanense.fin.ec:8088/api",
  //   urlApiGolang: "http://localhost:8088/api",
  urlApiGolangCredito: "https://sistemflm.futurolamanense.fin.ec:8089/api",
  urlApiGolangSimulador: "https://sistemflm.futurolamanense.fin.ec:8095/api",
  urlApiSimulador: "https://api.futurolamanense.fin.ec/api",
  //   urlApiSimulador: "http://localhost:8080/api",
  urlApiPremios: "https://api.futurolamanense.fin.ec/",
  urlQuejasReclamosArchivos:
    "https://sistemflm.futurolamanense.fin.ec/quejasyreclamos/",
  urlNoticias: "https://sistemflm.futurolamanense.fin.ec:3001/",
  urlImagenesNoticias:
    "https://sistemflm.futurolamanense.fin.ec/images_seccion_noticias/",
};

/*
const UrlGlobal = {
    urlApi: "https://apirest.futurolamanense.fin.ec/api-futuro-informativa/public",
    urlArchivos: "https://apirest.futurolamanense.fin.ec/archivosPlantillas/",
    urlApiGolang: "https://sistemflm.futurolamanense.fin.ec:8088/api",
    urlApiGolangCredito: "https://sistemflm.futurolamanense.fin.ec:8089/api",
    urlApiGolangSimulador: 'https://sistemflm.futurolamanense.fin.ec:8095/api',
    urlApiSimulador: "https://api.futurolamanense.fin.ec/api",
}
*/

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function fechaActual() {
  var date = new Date();
  var anio = date.getFullYear();
  var mes = +date.getMonth() + 1;
  var dia = date.getDate();

  if (mes < 10) {
    if (dia < 10) {
      return anio + "-0" + mes + "-0" + dia;
    } else {
      return anio + "-0" + mes + "-" + dia;
    }
  } else {
    if (dia < 10) {
      return anio + "-" + mes + "-0" + dia;
    } else {
      return anio + "-" + mes + "-" + dia;
    }
  }
}

function horaActual() {
  var date = new Date();
  return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
}

function formatDateTime(dateTime) {
  if (!dateTime) return "Fecha no disponible";

  try {
    const date = parseISO(dateTime);
    return format(date, "dd/MM/yyyy HH:mm"); // Cambia el formato según tus necesidades
  } catch (error) {
    console.error("Error formateando la fecha:", error);
    return "Fecha inválida";
  }
}

export default { UrlGlobal, uuidv4, fechaActual, horaActual, formatDateTime };
