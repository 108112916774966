import React from "react";
import codigoetica from "../../imagenes/codigoetica.png";
import "../../styles/CodigoeEtica.css";

class CodigoEtica extends React.Component {
  render() {
    const cardData = [
      {
        title: "Integridad",
        icon: "bx bx-check-circle",
        description:
          "Somos coherentes entre lo que decimos y hacemos en base a los valores y principios cooperativos. Reflejar pasión en lo que hacemos.",
        class: "integridad",
      },
      {
        title: "Transparencia",
        icon: "bx bx-show",
        description:
          "Presentamos de manera oportuna y objetiva los resultados alcanzados.",
        class: "transparencia",
      },
      {
        title: "Solidaridad",
        icon: "bx bx-heart",
        description:
          "Priorizar el bien o el interés común por sobre el interés particular.",
        class: "solidaridad",
      },
      {
        title: "Compromiso",
        icon: "bx bx-like",
        description:
          "Empoderarnos de las responsabilidades adquiridas en las actividades a desempeñar y cumplirlas con entera convicción de apoyar al desarrollo social y económico de nuestros socios.",
        class: "compromiso",
      },
      {
        title: "Confianza",
        icon: "bx bx-shield",
        description:
          "Brindamos seguridad integral a través de la reciprocidad de la confianza depositada por nuestros socios.",
        class: "confianza",
      },
    ];

    return (
      <React.Fragment>
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1 className="mb-4">CÓDIGO DE ÉTICA</h1>
                <h2 className="mb-4 texto-justificado">
                  En nuestra Cooperativa de Ahorro y Crédito, actuamos con
                  principios éticos que garantizan integridad, respeto y
                  transparencia en cada interacción. Nuestro Código de Ética y
                  Comportamiento establece las normas y valores que rigen a
                  directivos, colaboradores y socios, fortaleciendo la confianza
                  y el compromiso con nuestra comunidad. ¡Juntos construimos un
                  futuro basado en la honestidad y la responsabilidad!
                </h2>
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <a href="#" className="btn-get-started">
                    Conoce Nuestro Código de Ética
                  </a>
                </div>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img
                  src={codigoetica}
                  className="img-fluid rounded w-75"
                  alt="img-codigoEtica"
                />
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section className="services section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>NUESTROS VALORES FUNDAMENTALES</h2>
              </div>
              <div className="container-card">
                {cardData.map((item, i) => (
                  <div className="card-etica" key={i}>
                    <div className="card-img">
                      <i className={`${item.class} ${item.icon}`}></i>
                    </div>
                    <div className="card-info">
                      <p className={`text-title ${item.class}`}>{item.title}</p>
                      <p className="text-body">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section className="services section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>CUMPLIMIENTO Y CONSECUENCIAS</h2>
              </div>
              <p style={{ fontSize: "large", textAlign: "justify" }}>
                Cualquier incumplimiento de este código, ya sea por parte de un
                colaborador, directivo, será tratada con seriedad. Dependiendo
                de la naturaleza y gravedad de la infracción, las consecuencias
                pueden incluir sanciones disciplinarias internas o, en los casos
                más graves, la terminación de la relación con la cooperativa,
                además de las implicaciones legales que correspondan.
              </p>
              <p style={{ fontSize: "large", textAlign: "justify" }}>
                En resumen, el <b>Código de Ética y Comportamiento</b> de
                nuestra cooperativa no solo establece las pautas para el
                comportamiento de nuestros colaboradores, sino que también
                refleja nuestro compromiso con nuestros socios/clientes y la
                comunidad. Actuamos con transparencia, honestidad y
                responsabilidad en todo momento, buscando siempre ofrecer un
                servicio de calidad y contribuir al bienestar común.
              </p>
            </div>
          </section>
          <section id="lavadoActivos" className="services section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>
                  Prevención de Lavado de Activos, Financiamiento del Terrorismo
                  y Otros Delitos
                </h2>
              </div>

              {/* Video */}
              <div className="d-flex justify-content-center mb-4">
                <video
                  id="video-UAFE"
                  src="./videos/UAFE.mp4"
                  controls
                  height={"80%"}
                  width={"80%"}
                ></video>
              </div>

              <p style={{ fontSize: "large", textAlign: "justify" }}>
                En nuestra Cooperativa de Ahorro y Crédito, estamos
                profundamente comprometidos con la seguridad financiera de
                nuestros socios y la integridad del sistema económico en
                general. Para lograrlo, implementamos rigurosos mecanismos de
                prevención y control en relación con el{" "}
                <b>
                  Lavado de Activos, el Financiamiento del Terrorismo y otros
                  delitos financieros
                </b>
                .
              </p>
              <p style={{ fontSize: "large", textAlign: "justify" }}>
                Nuestro enfoque se basa en cumplir con las normativas nacionales
                e internacionales en materia de prevención, garantizando que
                todas nuestras operaciones sean transparentes, legales y
                éticamente responsables. La prevención de delitos financieros es
                fundamental para mantener la confianza de nuestros socios y
                contribuir a la estabilidad económica y social.
              </p>
              <div className="text-center fw-bold fs-4">
                <p>¡Somos parte del Sistema Antilavado del Ecuador!</p>
                <p>¡Protegemos la Economía Legal y Cumplimos con la Ley!</p>
                <p>¡Promovemos la Cultura Antilavado por El Nuevo Ecuador!</p>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default CodigoEtica;
