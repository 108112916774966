import React from "react";
import MedicinaGeneralImg from "../../imagenes/baners-paginas/medicinageneral.png";

class MedicinaGeneral extends React.Component {
    render() {
        return (
          <React.Fragment>
            <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h1>Servicio de Medicina General</h1>
                    <h2>
                      COAC Futuro Lamanense pone a disposición el servicio de
                      medicina general gratuito para socios con fondo Mortuorio.
                    </h2>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src={MedicinaGeneralImg}
                      className="img-fluid rounded w-50"
                      alt="img-medicinaGeneral"
                    />
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="services" className="services section-bg">
                <br /> <br />
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div
                      className="col-12 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Ofrecemos
                        </h5>
                      </div>
                      <ul style={{ fontSize: "large" }}>
                        <li>Consulta médica</li>
                        <li>
                          Diagnóstico, tratamiento y seguimiento de enfermedades
                          crónicas no trasmisibles (DIABETES MELLITUS,
                          HIPERTENSION, ANEMIA)
                        </li>
                        <li>Control de niños, adolecentes y adulto mayor.</li>
                        <li>Nebulizaciones.</li>
                        <li>Revisión y limpieza de oídos.</li>
                        <li>Desparasitación.</li>
                        <li>Curación de heridas.</li>
                        <li>
                          Toma de signos vitales(tensión arterial, peso, talla,
                          IMC, glucosa)
                        </li>
                        <li>Planificación familiar</li>
                      </ul>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-12 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Agenda tu cita
                        </h5>
                      </div>
                      <span className="ps-1" style={{ fontSize: "large" }}>
                        Ahora puedes agendar tu cita de manera fácil y rápida a
                        través de nuestra{" "}
                        <a
                          href="https://bit.ly/billeteradigital"
                          target="_blank"
                        >
                          aplicación móvil
                        </a>{" "}
                        o{" "}
                        <a
                          href="https://bdfl.futurolamanense.fin.ec/login"
                          target="_blank"
                        >
                          banca web
                        </a>
                        , fácil, rápido y seguro.
                      </span>
                    </div>
                  </div>
                  <br />
                </div>
                <br />
              </section>
            </main>
          </React.Fragment>
        );
    }
}

export default MedicinaGeneral;