const data = [
  {
    image: "assets/img/ahorro-incremento.jpg",
    title: "Ahorro con incremento de saldos",
    description:
      "Haz crecer tu cuenta de ahorros y gana puntos por cada incremento en tu saldo mensual.",
    delay: 100,
    detailsHead: ["Detalle", "Puntos"],
    details: [
      {
        comment: "Por un incremento neto de $10 en su saldo mensual",
        point: [1],
      },
      {
        comment: "Por un incremento neto de $100 en su saldo mensual",
        point: [12],
      },
      {
        comment: "Por un incremento neto de $1000 en su saldo mensual",
        point: [50],
      },
    ],
    conditions: [
      "Los puntos se calculan según el saldo promedio final de cada mes.",
      "No se otorgan puntos por depósitos retirados en el mismo mes",
      "Los incrementos netos se calculan como: Depósitos - Retiros",
    ],
    faq: [
      {
        question: "¿Cómo puedo aperturar una cuenta de ahorros?",
        to: "/cuenta-ahorros",
      },
    ],
    benefits: [],
  },
  {
    image: "assets/img/inversion.jpg",
    title: "Inversiones a plazo fijo",
    description:
      "Realiza inversiones a plazo fijo y recibe puntos según el monto y el tiempo de inversión.",
    delay: 200,
    detailsHead: [
      "Monto inversión",
      "31-90 días (puntos)",
      "91-180 días (puntos)",
      "181-360 días (puntos)",
      "(+) de 361 días (puntos)",
    ],
    details: [
      {
        comment: "$100 - $1,000",
        point: [2, 5, 10, 20],
      },
      {
        comment: "$1,001 - $5,000",
        point: [3, 7, 15, 30],
      },
      {
        comment: "$5,001 - $10,000",
        point: [5, 10, 20, 40],
      },
      {
        comment: "$10,001 - $20,000",
        point: [10, 15, 30, 50],
      },
      {
        comment: "$20,001 - $50,000",
        point: [20, 25, 50, 100],
      },
      {
        comment: "$50,001 - $100,000",
        point: [25, 50, 100, 200],
      },
    ],
    conditions: [
      "Los puntos se acreditan al finalizar el plazo sin retiros anticipados.",
      "Renovar el capital de depósito suma 10% extra de puntos.",
      "Los intereses no generan puntos.",
    ],
    faq: [
      {
        question: "¿Cómo puedo invertir mi dinero en la cooperativa?",
        to: "/deposito-plazo-fijo",
      },
    ],
    benefits: [],
  },
  {
    image: "assets/img/recomendacion.jpg",
    title: "Recomienda y gana",
    description:
      "Invita a tus amigos y familiares a unirse a nuestra cooperativa utilizando tu código de recomendación.",
    delay: 300,
    detailsHead: ["Detalle", "Puntos"],
    details: [
      {
        comment:
          "Si la persona referida apertura una cuenta con un depósito menor a $50",
        point: [10],
      },
      {
        comment:
          "Si la persona referida abre una cuenta con un depósito inicial de $50 o más.",
        point: [20],
      },
      {
        comment:
          "Si la persona referida apertura una cuenta y activa un crédito nuevo",
        point: [50],
      },
    ],
    conditions: [
      "La persona referida no debe haber sido socio previamente de la cooperativa.",
      "Para que los puntos sean válidos, el referido debe proporcionar el código del socio al momento de su apertura de cuenta",
    ],
    faq: [
      {
        question: "¿Cómo puedo obtener mi código de recomendación?",
        to: "/recomienda-y-gana",
      },
    ],
    benefits: [],
  },
  {
    image: "assets/img/pago-puntual.jpg",
    title: "Pagos puntuales",
    description: "Ser puntual con tus pagos de crédito tiene recompensa.",
    delay: 400,
    detailsHead: ["Monto del crédito", "Puntos"],
    details: [
      {
        comment: "$100 - $1,000",
        point: [5],
      },
      {
        comment: "$1,001 - $5,000",
        point: [10],
      },
      {
        comment: "$5,001 - $20,000",
        point: [20],
      },
      {
        comment: "$20,001 - $80,000",
        point: [50],
      },
    ],
    conditions: [],
    faq: [],
    benefits: [
      "Créditos de $100 a $5,000: 50 puntos",
      "Créditos de $5,001 a $20,000: 75 puntos.",
      "Créditos de $20,001 a $80,000: 100 puntos",
    ],
  },
];

export default data;
