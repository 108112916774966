import React from "react";
import creditoFutuSalud from "./../../../imagenes/slider/futusalud-mobile.jpg";
import SimuladorCredito from "../simulador-credito";

class CreditoFutuSalud extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="hero" className="d-flex align-items-center">
          <div className="container ">
            <div className="row">
              <div
                className=" col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1>Crédito Futusalud</h1>
                <h2 className="mb-3 texto-justificado">
                  ¿Enfrentas una emergencia médica? Este crédito está diseñado
                  para ayudarte a cubrir gastos de salud inesperados. Incluye a
                  tus familiares cercanos y se paga con los ingresos generados
                  por tus actividades. ¡No dejes que una emergencia frene tu
                  bienestar!
                </h2>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img
                  src={creditoFutuSalud}
                  className="rounded img-fluid w-50"
                  alt="credito-futusalud"
                />
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section id="services" className="services section-bg">
            <br /> <br />
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div
                  className="col-xl-12 col-md-6 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Requisitos
                    </h5>
                  </div>
                  <ul style={{ fontSize: "large" }}>
                    <li>
                      {" "}
                      Cédula de identidad y papeleta de votación (No aplica para
                      socios mayores a 65 años).
                    </li>
                    <li>Justificación de ingresos (últimos 3 meses).</li>
                    <li>Planilla de servicio básico.</li>
                    <li>Impuesto predial.</li>
                  </ul>
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 col-md-6 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Tasa de Interés
                    </h5>
                  </div>
                  <span className="ps-4" style={{ fontSize: "large" }}>
                    Accede a un crédito de hasta $2.000, con plazos de hasta 24
                    meses y una tasa competitiva del 14,50%.
                  </span>
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 col-md-6 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Aspectos Clave
                    </h5>
                  </div>
                  <ul style={{ fontSize: "large" }}>
                    <li>Tu crédito se aprueba en un plazo de 24 a 48 horas.</li>
                    <li>
                      Acceso a nuestros servicios de medicina general,
                      odontología, peluquería y un fondo mortuorio de hasta
                      $1.000
                    </li>
                    <li>
                      Recuerda que los pagos de tu crédito deben realizarse
                      directamente a la clínica o hospital donde recibiste la
                      atención médica.
                    </li>
                  </ul>
                </div>
              </div>
              <br />

              <SimuladorCredito
                codigotipocredito={"015"}
                tipocredito={"FUTUSALUD"}
              />

              <br />
            </div>
            <br />
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default CreditoFutuSalud;
