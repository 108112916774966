import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../styles/Slider-swiper.css";
class CreditoInversiones extends React.Component {
  // Arreglo de créditos
  creditosInversiones = [
    {
      link: "/credito-consumo",
      title: "Crédito de Consumo",
      description:
        "Un crédito destinado a cumplir tus sueños y anhelos, financiamos la compra de bienes y pago de servicios, para que hagas realidad todos tus proyectos con flexibilidad y respaldo financiero.",
      delay: 100,
    },
    {
      link: "/credito-inmobiliario",
      title: "Crédito Inmobiliario",
      description:
        "Es hora de hacer realidad tu sueño de tener una vivienda propia. Te ofrecemos el apoyo y financiamiento que necesitas para construir, comprar o mejorar el hogar que siempre has deseado.",
      delay: 200,
    },
    {
      link: "/microcredito",
      title: "Microcrédito",
      description:
        "Queremos verte crecer, por eso te ofrecemos el mejor crédito para tu negocio, accede hasta 80.000, a 144 meses plazo, con frecuencia de pago mensual o semestral.",
      delay: 300,
    },
    {
      link: "/eco-futuro",
      title: "Eco Futuro",
      description:
        "Financiamos proyectos ecológicos, apoyando la adquisición de materias primas y equipos sostenibles. Impulsamos la eficiencia energética y el cuidado del medioambiente.",
      delay: 400,
    },
    {
      link: "/credito-futuconfianza",
      title: "Crédito Futuconfianza",
      description:
        "Este crédito apoya a nuestros socios con negocios establecidos en comercio, servicios o producción. Te brindamos el respaldo que necesitas para impulsar tu crecimiento y alcanzar tus metas.",
      delay: 500,
    },
    {
      link: "/futu-aniversario",
      title: "Crédito Futuaniversario",
      description:
        "Crédito exclusivo por el aniversario de nuestra cooperativa para socios con negocios en comercio, servicio o producción. Disponible solo en marzo, ideal para capital de trabajo o inversión en activos fijos.",
      delay: 600,
    },
    {
      link: "/futu-auto",
      title: "Crédito Futuauto",
      description:
        "Financiamiento del 100% para vehículos livianos nuevos o seminuevos (hasta 5 años). Exclusivo para socios con vínculo directo o indirecto, brindando opciones accesibles y flexibles para tu movilidad.",
      delay: 700,
    },
    {
      link: "/futu-salud",
      title: "Crédito Futusalud",
      description:
        "Crédito para emergencias de salud, dirigido a personas naturales y su núcleo familiar cercano. Respaldado por ingresos propios, brindando apoyo financiero en momentos críticos.",
      delay: 800,
    },
    {
      link: "/credito-violeta",
      title: "Crédito Violeta",
      description:
        "Crédito dirigido a mujeres emprendedoras en actividades de producción, artesanía, comercio o servicios, así como a aquellas con relación de dependencia. Apoyo financiero para impulsar su crecimiento.",
      delay: 900,
    },
    {
      link: "/credito-premium",
      title: "Crédito Premium",
      description:
        "Crédito dirigido a socios con negocios establecidos en comercio, servicio o producción, para capital de trabajo o activos fijos. Aplica para socios novados y recurrentes.",
      delay: 1000,
    },
    // {
    //   link: "/emprende-futuro",
    //   title: "Crédito Violeta",
    //   description:
    //     "Un crédito destinado a mujeres emprendedoras y visionarias con ganas de cumplir sus sueños y anhelos, con la tasa más baja del mercado.",
    //   delay: 400,
    // },
  ];

  render() {
    return (
      <React.Fragment>
        <section id="services" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Nuestros Créditos</h2>
              <p>
                Impulsa tus proyectos con el crédito ideal para ti. Explora
                nuestras opciones y encuentra la solución financiera que
                necesitas.
              </p>
            </div>
            <Swiper
              slidesPerView={1}
              slidesPerGroupSkip={1}
              centeredSlides={false}
              spaceBetween={10}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                980: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 3,
                },
              }}
              grabCursor={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Navigation, Pagination, Autoplay]}
            >
              {this.creditosInversiones.map((credito, index) => (
                <SwiperSlide key={index} className="slide">
                  <div
                    className="slide-item"
                    data-aos="zoom-in"
                    data-aos-delay={credito.delay}
                  >
                    <div className="icon-box">
                      <h4 className="text-center text-primario fw-bold">
                        {credito.title}
                      </h4>
                      <p className="texto-justificado">{credito.description}</p>
                      <Link to={credito.link}>
                        <div
                          className="mt-5 w-100 text-center p-2 rounded-2"
                          style={{ backgroundColor: "#47b2e4" }}
                        >
                          <span style={{ color: "white" }}>
                            Más información
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default CreditoInversiones;
