import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import apiMensajes from "../../services/mensajesApi";
// import Mensajes from "../../models/Mensajes";
// import BannerAntilavados from './../../imagenes/bannerantilavados.png';

class FacebookRecomendaciones extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  // state = {
  //     form: {
  //         'nombre': '',
  //         'email': '',
  //         'asunto': '',
  //         'mensaje': ''
  //     }
  // }

  // limpiarForm = () => {
  //     this.setState({
  //         form: {
  //             'nombre': '',
  //             'email': '',
  //             'asunto': '',
  //             'mensaje': ''
  //         }
  //     })
  // }

  // agregarMensaje = async (e) => {
  //     e.preventDefault();
  //     try {
  //         let mensaje = new Mensajes(null, this.state.form.nombre, this.state.form.email, this.state.form.asunto, this.state.form.mensaje);
  //         await apiMensajes.mensajes.create(mensaje);
  //         this.limpiarForm();
  //         toast.success("Tu mensaje se a enviado correctamente");
  //     } catch (error) {
  //         toast.error("error" + error);
  //     }
  // }

  // handleChange = (e) => {
  //     this.setState({
  //         form: {
  //             ... this.state.form,
  //             [e.target.name]: e.target.value,
  //         }
  //     })
  // }

  componentDidMount() {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src =
        "https://connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v15.0";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        <section id="contact" className="contact section-bg">
          <div className="container" data-aos="fade-up">
            {/* <img src={BannerAntilavados} className="w-100 rounded mb-3" />
                        <br /> <br /> */}
            <div className="section-title">
              <h2>¿Quieres saber más sobre nosotros?</h2>
              <p>
                Siguenos en nuestras redes sociales para estar al tanto de
                nuestras publicaciones.
              </p>
            </div>
            <div className="row">
              <div
                className="col-12 d-flex justify-content-center"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div id="fb-root"></div>
                <div
                  className="fb-page facebookpp"
                  data-href="https://www.facebook.com/COACFUTLAMANENSE"
                  data-tabs="timeline"
                  data-width="800"
                  data-height="700"
                  data-small-header="false"
                  data-adapt-container-width="true"
                  data-hide-cover="false"
                  data-show-facepile="true"
                >
                  <blockquote
                    cite="https://www.facebook.com/COACFUTLAMANENSE"
                    className="fb-xfbml-parse-ignore"
                    style={{width: 800}}
                  >
                    <a href="https://www.facebook.com/COACFUTLAMANENSE">
                      Facebook
                    </a>
                  </blockquote>
                </div>
              </div>

              {/* <div className="col-lg-7 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
                                <form className="php-email-form" onSubmit={this.agregarMensaje}>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label >Tu Nombre</label>
                                            <input type="text" className="form-control" onChange={this.handleChange} name="nombre" value={this.state.form.nombre} required />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label >Tu Email</label>
                                            <input type="email" className="form-control" onChange={this.handleChange} name="email" value={this.state.form.email} required />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label >Asunto</label>
                                        <input type="text" className="form-control" onChange={this.handleChange} name="asunto" value={this.state.form.asunto} required />
                                    </div>
                                    <div className="form-group">
                                        <label >Mensaje</label>
                                        <textarea className="form-control" rows="10" onChange={this.handleChange} name="mensaje" value={this.state.form.mensaje} required></textarea>
                                    </div>
                                    <div className="text-center"><button type="submit">Enviar Mensaje</button></div>
                                </form>
                            </div> */}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default FacebookRecomendaciones;
