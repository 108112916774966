import React from "react";
import apiSucursal from "../../services/sucursalApi";
import ImgUbicacion from "../../imagenes/ubicacion.jpg";
import ImgAtencion from "../../imagenes/atencion.png";
import ImgChat from "../../imagenes/chat.png";

class Sucursales extends React.Component {

    state = {
        sucursales: [],
        loading: false,
        error: false
    };

    constructor(props, context) {
        super(props, context);
    }

    getSucursales = async () => {
        this.setState({ loading: true });
        try {
            const data = await apiSucursal.sucursal.getall();
            this.setState({ loading: false, error: false, sucursales: data.response });
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, error: true });
        }
    }

    spinner = () => {
        if (this.state.loading) {
            return (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    Cargando...
                </div>
            );
        } else {
            return;
        }
    }

    componentDidMount() {
        this.getSucursales();
    }

    render() {
        return (
          <React.Fragment>
            {this.spinner()}
            <section className="contact-section">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h2>Estamos aquí para ti</h2>
                </div>

                <div className="row text-center justify-content-around">
                  {/* Ubícanos */}
                  <div
                    className="col-lg-3"
                    data-aos="fade-right"
                    data-aos-delay="100"
                  >
                    <img
                      src={ImgUbicacion}
                      alt="Ubicación"
                      className="img-fluid"
                      style={{ width: "29%" }}
                    />
                    <a
                      className="mt-3 mb-3 fs-4 d-block fw-semibold text-primario"
                      href="/agencias"
                    >
                      Ubícanos
                    </a>
                    <p>
                      Localiza la agencia más cercana y obtén asesoría
                      personalizada.
                    </p>
                  </div>

                  {/* Quejas y Sugerencias */}
                  <div
                    className="col-lg-3"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <img
                      src={ImgChat}
                      alt="Chat"
                      className="img-fluid"
                      style={{ width: "21%" }}
                    />
                    <a
                      className="mt-3 mb-3 fs-4 d-block fw-semibold text-primario"
                      href="/quejas-reclamos-sugerencias/1"
                    >
                      Coméntanos
                    </a>
                    <p>
                      Comparte tus sugerencias, quejas o reclamos. ¡Tu opinión
                      nos importa!
                    </p>
                  </div>

                  {/* Escríbenos */}
                  <div
                    className="col-lg-3"
                    data-aos="fade-left"
                    data-aos-delay="300"
                  >
                    <img
                      src={ImgAtencion}
                      alt="Atención"
                      className="img-fluid"
                      style={{ width: "31%" }}
                    />
                    <a
                      className="mt-3 mb-3 fs-4 d-block fw-semibold text-primario"
                      href="/contactos"
                    >
                      Escríbenos
                    </a>
                    <p>
                      Envíanos tu mensaje y te responderemos en breve, con la
                      atención que mereces.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            {/* <section className="about">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>NUESTRAS SUCURSALES</h2>
                        </div>

                        <div className="row content">

                            <div className="col-lg-4 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
                                <img src={ImageMatriz} className="img-fluid " alt="" />
                            </div>

                            <div className="col-lg-8 ">
                                <div className="row content">
                                    {
                                        this.state.sucursales.map((sucursal) => {
                                            return (
                                                <div className="col-lg-6 pb-4" key={sucursal.idsucursal}>
                                                    <h4><a >{sucursal.nombre}</a></h4>
                                                    <p>
                                                        <b>Dirección: </b> {sucursal.direccion}
                                                    </p>
                                                    <p>
                                                        <b>Teléfono: </b> {sucursal.telefono}
                                                    </p>
                                                    <p>
                                                        <b>Email: </b> info@futurolamanense.fin.ec
                                                    </p>
                                                </div>
                                            );
                                        })
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                </section> */}
          </React.Fragment>
        );
    }
}
export default Sucursales;