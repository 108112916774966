import React from "react";
import apiVisitas from "../../services/visitasApi";
import AhorroInclusionJuniorImg from "../../imagenes/inclusion-junior.jpg"

class AhorroInclusionJunior extends React.Component {
  agregarVisitaAhorroInclusionJunior = async () => {
    try {
      await apiVisitas.visitas.create({ tipo: "Ahorro Inclusión Junior" });
    } catch (error) {
      console.log("error al crear visíta " + error);
    }
  };

  componentDidMount() {
    this.agregarVisitaAhorroInclusionJunior();
  }

  render() {
    return (
      <React.Fragment>
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1>Ahorro Inclusión Junior</h1>
                <h2 className="texto-justificado">
                  Es la cuenta de ahorro diseñada para niños, jóvenes, personas
                  con discapacidad y mujeres embarazadas que reciben el bono del
                  MIES. Te permite acceder a tu bono con facilidad y, al mismo
                  tiempo, fomenta el hábito del ahorro y la planificación
                  financiera desde una edad temprana.
                </h2>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img
                  src={AhorroInclusionJuniorImg}
                  className="w-75 img-fluid rounded"
                  alt="img-ahorroInclusioJunior"
                />
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section id="services" className="services section-bg">
            <br /> <br />
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div
                  className="col-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Requisitos
                    </h5>
                  </div>
                  <ul style={{ fontSize: "large" }}>
                    <li>Entre 0 - 17 años.</li>
                    <li>Copia a color de la cédula del menor.</li>
                    <li>
                      Copia a color de la cédula y la papeleta de votación del
                      representante
                    </li>
                    <li>
                      Copia de la última planilla de cualquier servicio básico.
                    </li>
                    <li>Correo electrónico</li>
                  </ul>
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Monto de Apertura
                    </h5>
                  </div>
                  <span style={{ fontSize: "large" }}>
                    El monto requerido para la apertura de la cuenta es de USD
                    $20.00
                  </span>
                  <br />
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-12 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Beneficios
                    </h5>
                  </div>
                  <ul style={{ fontSize: "large" }}>
                    <li>
                      Gestiona tu cuenta desde cualquier lugar con nuestra{" "}
                      <a
                        href="https://play.google.com/store/apps/details?id=futuro.lamanence.fin"
                        target="_blank"
                      >
                        aplicación móvil
                      </a>{" "}
                      o{" "}
                      <a
                        href="https://bdfl.futurolamanense.fin.ec/login"
                        target="_blank"
                      >
                        banca web
                      </a>
                      , fácil, rápido y seguro.
                    </li>
                    <li>Partipación en sorteos trimestrales.</li>
                    <li>
                      Acceso a nuestros servicios de medicina general,
                      odontología y peluquería.
                    </li>
                  </ul>
                </div>
              </div>
              <br />
            </div>
            <br />
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default AhorroInclusionJunior;
