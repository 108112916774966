import React from "react";
import '../App.css';
import Slider from './components/slider';
import Sucursales from "./components/sucursales";
import FacebookRecomendaciones from "./components/contacto";
import Suscribete from "./components/suscribete";
import CreditoInversiones from "./components/creditoinversiones";
import Ahorroproductos from "./components/Ahorroproductos";
import SeguroDepositos from "./components/Segurodepositos";
import Beneficios from "./components/beneficios";
import apiVisitas from "../services/visitasApi";
import 'react-responsive-modal/styles.css';
import SistemaAntilavado from "./components/SistemaAntilavado";
import { ToastContainer } from "react-toastify";
import SeguridadInformacion from "./components/Seguridadinformacion";

class Index extends React.Component {

    state = {
        open: false,
        imgCargada: false
    };

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        this.agregarVisita();
        //this.onOpenModal();
    }

    imagenCargada = () => {
        this.setState({ imgCargada: true })
    }

    agregarVisita = async (e) => {
        try {
            await apiVisitas.visitas.create({ tipo: "Index" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    botones = () => {
        if (this.state.imgCargada == true) {
            return (
                <div className="row">
                    <div className="col-lg-6 text-center">
                        <a className="btnn" href="https://matriculas.figlac.org/futuro-lamanense/" target="_blank">
                            <i className="bi bi-arrow-up-right-square"></i> Inscríbete en los cursos
                        </a>
                    </div>
                    <div className="col-lg-6">
                        <a className="btnn" href="https://campus.figlac.org/course/view.php?id=22" target="_blank">
                            <i className="bi bi-play-circle"></i> Acceder a la Aula Virtual
                        </a>
                    </div>
                </div>
            )
        }
    }

    render() {
        const { open } = this.state;
        return (
          <React.Fragment>
            <ToastContainer position="top-right" autoClose={3000} />
            {/* 
                    <Modal open={open} onClose={this.onCloseModal}>
                    <div className="w-100">
                        <img className="w-100" src={MoneyWeek} onLoad={this.imagenCargada} />
                        {this.botones()}
                    </div>
                </Modal>
                    */}

            <Slider />
            <main id="main" className="mt-0 pt-0">
              <Ahorroproductos />

              <SeguroDepositos />

              <CreditoInversiones />

              <SeguridadInformacion />

              <Beneficios />

              <SistemaAntilavado />

              <FacebookRecomendaciones />

              <Sucursales />
            </main>

            {/* <footer id="footer">
              <Suscribete />
            </footer> */}

            <div id="preloaderd"></div>
            <a
              href="#"
              className="back-to-top d-flex align-items-center justify-content-center"
            >
              <i className="bi bi-arrow-up-short"></i>
            </a>
          </React.Fragment>
        );
    }
}

export default Index;