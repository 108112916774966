import React from "react";
import PremiosImg from "../../imagenes/acumula-puntos.jpg";
import apiVisitas from "../../services/visitasApi";
import apiPremios from "../../services/ganaPuntosApi";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../styles/GanaPuntos.css";
import data from "../../mock/futuroPremia";

class FuturoTePremia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPointClient: null,
      listAwards: [],
      loading: false,
      error: false,
      form: {
        buscadortxt: "",
        year: new Date().getFullYear(),
      },
      selectedAward: null,
      selectMethodWin: null,
      selectDetailAward: null,
      existeCliente: "vacio",
      currentPage: 1,
      itemsPerPage: 5,
    };
  }
  cards = data;

  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  agregarVisitaFuturoTePremia = async () => {
    try {
      await apiVisitas.visitas.create({ tipo: "Futuro Te Premia" });
    } catch (error) {
      console.log("Error al registrar la visita: " + error);
    }
  };

  getPremios = async () => {
    this.setState({ loading: true });
    try {
      const data = await apiPremios.listAwards.create();
      this.setState({
        loading: false,
        error: false,
        listAwards: data.response,
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, error: true });
    }
  };

  componentDidMount() {
    this.getPremios();
    this.agregarVisitaFuturoTePremia();
  }

  searchPerson = async () => {
    this.setState({ loading: true, selectDetailAward: null });
    try {
      if (this.state.form.buscadortxt === "") {
        return;
      }

      const data = await apiPremios.getSecuencialClient.create(
        this.state.form.buscadortxt
      );

      if (data.response.length > 0) {
        // Listar Puntos
        const pointClient = await apiPremios.listScoreTotal.create({
          secuencialCliente: data.response[0].secuencialCliente,
          year: this.state.form.year,
        });

        if (pointClient.response) {
          this.setState({
            existeCliente: "existe",
            dataPointClient: pointClient.response,
          });

          // Detalle de puntos
          const detailPointClient = await apiPremios.listDetailScore.create({
            secuencialCliente: data.response[0].secuencialCliente,
            year: this.state.form.year,
          });

          this.setState({
            selectDetailAward: detailPointClient.response,
          });
        } else {
          this.setState({
            existeCliente: "existe",
            dataPointClient: {
              anio: 2025,
              puntosAcumulados: 0,
              secuencialCliente: data.response[0].secuencialCliente,
            },
          });
        }
      } else {
        this.setState({
          existeCliente: "noexiste",
          dataPointClient: null,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, error: true, existeCliente: "vacio" });
    } finally {
      this.state.form.buscadortxt = "";
    }
  };

  pointShow = () => {
    if (this.state.existeCliente === "existe") {
      return (
        <div className="box">
          <h4 className="text-success fw-bold">
            🎁 ¡Tienes {this.state.dataPointClient.puntosAcumulados} puntos para
            canjear! 🎉
          </h4>
          <h5 className="texto-justificado">
            Explora nuestro <Link to={"#catalogo"}>Catálogo de Premios</Link> y
            elige entre una variedad de opciones como viajes, electrodomésticos,
            artículos deportivos y mucho más. Acércate a cualquiera de nuestras{" "}
            <Link to={"/agencias"}>agencias</Link> y canjea tus puntos por
            experiencias y productos increíbles. ¡Tu fidelidad merece ser
            premiada! ✨
          </h5>
          <div className="d-flex justify-content-center">
            <button
              className="btnn"
              data-bs-toggle="modal"
              data-bs-target="#awardDetailModal"
            >
              Detalle de puntos
            </button>
          </div>
        </div>
      );
    } else if (this.state.existeCliente === "noexiste") {
      return (
        <div className="box">
          <h4 className="text-warning fw-bold">
            🚨 ¡Aún no eres socio de la cooperativa! 🚨
          </h4>
          <h5 className="texto-justificado">
            Únete hoy abriendo una{" "}
            <Link to={"/cuenta-ahorros"}>cuenta de ahorros</Link> en cualquiera
            de nuestras <Link to={"/agencias"}>agencias</Link> y comienza a
            acumular puntos automáticamente. ¡Cánjealos por increíbles premios
            como viajes, electrodomésticos, balones y mucho más! No dejes pasar
            esta oportunidad de ganar fabulosos premios. 🎁✨
          </h5>
        </div>
      );
    }
  };

  openModal = (award) => {
    this.setState({ selectedAward: award });
  };

  openModalHowWinPoints = (data) => {
    this.setState({ selectMethodWin: data });
  };

  render() {
    return (
      <React.Fragment>
        <main id="main">
          <section id="hero" className="d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <h1>🎉 Gana puntos 🎉</h1>
                  <h2 className="texto-justificado">
                    Un programa exclusivo de la Cooperativa Futuro Lamanense
                    diseñado para premiar tu fidelidad y compromiso. Acumula
                    puntos con tus ahorros, inversiones, recomendaciones y pagos
                    puntuales.
                  </h2>
                  <h2 className="texto-justificado">
                    Mientras más ahorres, más oportunidades tienes de canjear
                    tus puntos por fabulosos premios. ¡Es nuestra forma de
                    agradecerte por confiar en nosotros!
                  </h2>
                  <div className="d-flex justify-content-center justify-content-lg-start">
                    <a href="#como-ganar" className="btn-get-started scrollto">
                      Descubre cómo ganar puntos
                    </a>
                  </div>
                </div>
                <div
                  className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <img
                    src={PremiosImg}
                    className="img-fluid w-50 rounded"
                    alt="img-GanaPuntos"
                  />
                </div>
              </div>
            </div>
          </section>

          {/* Como Ganar Puntos */}
          <section id="como-ganar" className="services section-bg">
            <div className="container" data-aos="fade-up">
              <h2 className="section-title text-center text-primario fw-bold">
                ¿Cómo Ganar Puntos?
              </h2>
              <p className="text-center">
                Ganar puntos es fácil. Solo necesitas participar activamente en
                los diferentes productos y servicios de la cooperativa.
                ¡Descubre todas las formas en las que puedes sumar puntos y
                alcanzar grandes premios!
              </p>
              <div className="row mt-4 row-gap-4">
                <Swiper
                  style={{ width: "100%", height: 450 }}
                  slidesPerView={1}
                  slidesPerGroupSkip={1}
                  centeredSlides={false}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    768: {
                      slidesPerView: 2,
                    },
                    1200: {
                      slidesPerView: 3,
                    },
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Navigation, Pagination, Autoplay]}
                >
                  {this.cards.map((item, index) => (
                    <SwiperSlide
                      key={index}
                      style={{ top: "5%" }}
                      className="d-flex justify-content-center"
                    >
                      <div
                        className="slide-item"
                        style={{ cursor: "pointer" }}
                        data-aos="zoom-in"
                        data-aos-delay={item.delay}
                      >
                        <div className="box-products">
                          <a
                            className="pe-auto"
                            data-bs-toggle="modal"
                            data-bs-target="#howWinPointsModal"
                            onClick={() => this.openModalHowWinPoints(item)}
                          >
                            <div className="icon d-flex justify-content-center">
                              <img src={item.image} alt={item.title} />
                            </div>
                            <h6 className="text-center mt-3 text-primario fs-5 fw-bold">
                              {item.title}
                            </h6>
                            <p className="texto-justificado text-secondary">
                              {item.description}
                            </p>
                          </a>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </section>

          {/* Consulta Puntos */}
          <section id="consulta" className="container" data-aos="fade-up">
            <h2 className="section-title text-primario fw-bold">
              🔍 Consulta tus Puntos
            </h2>
            <p className="text-center">
              Accede a la app móvil de la cooperativa o acude a nuestras{" "}
              <a href="/agencias">agencias</a> para conocer el saldo actual de
              tus puntos y las opciones disponibles para canjearlos.
            </p>
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <label className="ps-1 fw-bold">Identificación:</label>
                  <input
                    className="form-control"
                    onChange={this.handleChange}
                    name="buscadortxt"
                    value={this.state.form.buscadortxt}
                    maxLength={13}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.searchPerson();
                      }
                    }}
                    required
                  />

                  <div className="text-end mt-2">
                    <button
                      className="btnn"
                      onClick={() => this.searchPerson()}
                    >
                      Buscar
                    </button>
                  </div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-6">{this.pointShow()}</div>
              </div>
            </div>
          </section>

          {/* Catalogo premios */}
          <section
            id="catalogo"
            className="services section-bg"
            data-aos="fade-up"
          >
            <div className="container aos-init aos-animate">
              <h2 className="section-title text-center text-primario fw-bold">
                🎁 Catálogo de Premios 🎁
              </h2>
              <p className="text-center my-4">
                ¡Tus puntos tienen valor! Canjea tus puntos en la{" "}
                <a href="/agencias">agencia</a> más cercana y disfruta de una
                variedad de productos y experiencias diseñadas para premiar tu
                fidelidad.
              </p>
              <div className="card-container">
                {this.state.listAwards.map((item, index) => (
                  <div key={index} className="card-content">
                    <div className="img">
                      <img
                        src={apiPremios.urlBaseImage + item.imageMain}
                        alt={`award-${index}`}
                      />
                    </div>
                    <span>{item.points} Puntos</span>

                    <button
                      className="card-button"
                      data-bs-toggle="modal"
                      data-bs-target="#awardModal"
                      onClick={() => this.openModal(item)}
                    >
                      Ver más
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Modal Como ganar puntos */}
          <div
            className="modal fade"
            id="howWinPointsModal"
            tabIndex="-1"
            aria-labelledby="howWinPointsLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content w-100">
                <div id="winPoins" className="modal-header">
                  <h4
                    className="modal-title w-100 text-white"
                    id="howWinPointsLabel"
                  >
                    {this.state.selectMethodWin?.title}
                  </h4>
                  <button
                    type="button"
                    className="btn-close text-white fw-bold"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="table-responsive">
                    <table className="custom-table my-3">
                      <thead>
                        <tr>
                          {this.state.selectMethodWin?.detailsHead.map(
                            (c, index) => (
                              <th key={index}>{c}</th>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.selectMethodWin?.details.map(
                          (det, index) => (
                            <tr key={index}>
                              <td>{det.comment}</td>
                              {det.point.map((pt, i) => (
                                <td key={i}>{pt}</td>
                              ))}
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="award-details">
                    <div className="award-description">
                      {this.state.selectMethodWin?.conditions.length > 0 ? (
                        <div>
                          <span className="fw-bold">Condiciones: </span>
                          <ul>
                            {this.state.selectMethodWin?.conditions.map(
                              (req, index) => (
                                <li key={index}>{req}</li>
                              )
                            )}
                          </ul>
                        </div>
                      ) : (
                        <div>
                          <span className="fw-bold">Beneficios: </span>
                          <span>
                            Los socios que mantengan pagos puntuales durante
                            todo el año recibirán un bono de puntos adicionales:
                          </span>
                          <ul>
                            {this.state.selectMethodWin?.benefits.map(
                              (benf, index) => (
                                <li key={index}>{benf}</li>
                              )
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.selectMethodWin?.faq.length > 0 ? (
                    <div className="award-details mt-3">
                      <div className="award-description">
                        <div>
                          <p className="fw-bold mb-0">Preguntas Frecuentes: </p>
                          {this.state.selectMethodWin?.faq.map((fq, index) => (
                            <a key={index} href={fq.to}>
                              {fq.question}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Modal Premios */}
          <div
            className="modal fade"
            id="awardModal"
            tabIndex="-1"
            aria-labelledby="awardModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content">
                <div className="modal-header">
                  <h2
                    className="modal-title text-center w-100 text-white"
                    id="awardModalLabel"
                  >
                    {this.state.selectedAward?.points} puntos
                  </h2>
                  <button
                    type="button"
                    className="btn-close text-white fw-bold"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  {this.state.selectedAward && (
                    <>
                      <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        navigation={true}
                        autoplay={{
                          delay: 3000,
                          disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                        slidesPerView={1}
                      >
                        {this.state.selectedAward.images.map((slide, index) => (
                          <SwiperSlide key={index} className="swiper-content">
                            {this.state.selectedAward.id === 12 && (
                              <div className="limited-label">Cupo Limitado</div>
                            )}
                            <img
                              src={apiPremios.urlBaseImage + slide}
                              alt="Banner"
                            />
                            <span className="award-name">
                              {this.state.selectedAward.awards[
                                index
                              ].toUpperCase()}
                            </span>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <div className="award-details">
                        <div className="award-description">
                          <span className="fw-bold">Nota: </span>
                          <span>{this.state.selectedAward.description}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Modal Detalle Puntos */}
          <div
            className="modal fade"
            id="awardDetailModal"
            tabIndex="-1"
            aria-labelledby="awardDetailModalLabel"
          >
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content w-100">
                <div className="modal-header w-100">
                  <h4
                    className="modal-title text-center w-100 text-white"
                    id="awardDetailModalLabel"
                  >
                    Detalle de Premios
                  </h4>
                  <button
                    type="button"
                    className="btn-close text-white fw-bold"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped text-center">
                      <thead className="table-primary">
                        <tr>
                          <th style={{ width: "20%" }}>Fecha📅</th>
                          <th style={{ width: "60%" }}>Detalle🏆</th>
                          <th style={{ width: "20%" }}>Puntos⭐</th>
                        </tr>
                      </thead>
                    </table>

                    {/* Contenedor con scroll para el tbody */}
                    <div className="tbody-container">
                      <table className="table table-bordered table-striped text-center">
                        <tbody>
                          {this.state.selectDetailAward?.length > 0 ? (
                            this.state.selectDetailAward?.map((item, index) => (
                              <tr key={index}>
                                <td style={{ width: "20%" }}>
                                  {
                                    new Date(item.fechaSistema)
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                </td>
                                <td style={{ width: "60%" }}>{item.nombre}</td>
                                <td style={{ width: "20%" }}>
                                  <span className="badge bg-success fs-6">
                                    {item.puntos}
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={3}>No se encontraron resultados</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {/* Total de puntos */}
                    {this.state.selectDetailAward?.length > 0 && (
                      <table className="table table-bordered table-striped text-center mt-3">
                        <thead className="table-primary">
                          <tr>
                            <th style={{ width: "80%" }}>Total de puntos:</th>
                            <th style={{ width: "20%" }}>
                              {this.state.dataPointClient.puntosAcumulados}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default FuturoTePremia;
